import { makeStyles, Tabs } from '@material-ui/core';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../components/Buttons/Buttons';
import ApproveOfferDialog from '../../components/Dialogs/ApproveOfferDialog';
import DefaultDialog from '../../components/Dialogs/DefaultDialog';
import ReasonDialog from '../../components/Dialogs/ReasonDialog';
import AchievementForm from '../../components/Forms/AssignmentForm/AchievementForm';
import ChildProtectionForm from '../../components/Forms/AssignmentForm/ChildProtectionForm';
import EnvironmentForm from '../../components/Forms/AssignmentForm/EnvironmentForm';
import FormationForm from '../../components/Forms/AssignmentForm/FormationForm';
import MonitoringForm from '../../components/Forms/AssignmentForm/MonitoringForm';
import AttachmentForm from '../../components/Forms/AttachmentForm';
import ContactsForm from '../../components/Forms/Contacts/ContactsForm';
import { Action, useFormError, useOfferFormContext } from '../../components/Forms/context/OfferFormContext';
import CostPlanForm from '../../components/Forms/CostPlans/CostPlanForm';
import FormHead from '../../components/Forms/FormHead';
import FormTab, { FormTabItem } from '../../components/Forms/FormTab';
import MeasureForm from '../../components/Forms/Measures/MeasureForm';
import { StateType } from '../../enums/StateType';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { Box } from '@mui/material';
import { MandatoryAttachments } from '../../enums/MandatoryAttachments';
import { getMandatoryAttachmentKeys, mandatorySort } from '../../utils/attachment.util';
import { commonAttachmentTypes } from '../../value-maps/AttachmentTypes';
import { ContactDialogObject } from '../../interfaces/ContactDialogObject';
import { MReferent } from '../../sdk/com/apiomat/frontend/missio';
import { copyContact } from '../../utils/contact.utils';
import { notificationActions } from '../../store/notification';
import { ApplicationNewState } from '../../utils/application-state';
import { offerActions } from '../../store/offers';
import { setContactsOnOffer } from '../../utils/offer.utils';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
  titleContainer: {
    flex: 1,
    display: 'flex',
    paddingTop: 15,
    paddingBottom: 15,
  },
  buttonsContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    justifyItems: 'space-between',
  },
  saveButton: {
    width: window.innerWidth / 12,
    display: 'flex',
    borderRadius: 0,
  },
  separator: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  uploadButton: {
    width: window.innerWidth / 12,
    display: 'flex',
    borderRadius: 0,
  },
  titleTextFieldContainer: {
    flex: 1,
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  tabContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

type Props = {
  data: any;
  measureName: string | undefined | null;
  measureType: string | undefined | null;
  onMeasureChange: (name: string) => void;
  onTitleChange: (string) => void;
  readOnly: boolean;
  isProposal: boolean;
  referents: Array<MReferent>;
  title: string;
};

const NewAssignment: FunctionComponent<Props> = (
  {
    data,
    measureName,
    measureType,
    onMeasureChange,
    onTitleChange,
    readOnly,
    isProposal,
    referents,
    title,
  }) => {
  const { state } = useOfferFormContext();
  const formError = useFormError();
  const { isAdmin } = useSelector((state: AppState) => state.auth);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dispatch: formDispatch } = useOfferFormContext();

  const [ activeTabIndex, setActiveTabIndex ] = useState<number>(0);
  const [ isMissingFieldsDialogActive, setMissingFieldsDialogActive ] = useState<boolean>(false);
  const [ isRejectionDialogOpen, setIsRejectionDialogOpen ] = useState(false);
  const [ rejectionMessage, setRejectionMessage ] = useState('');
  const [ activeDialog, setActiveDialog ] = useState<ContactDialogObject>({
    isVisible: false,
    contacts: [],
    oldContacts: [],
  });

  const { currentContacts } = useSelector((state: AppState) => state.offer);
  const contacts = currentContacts.map(contact => contact.contactObject);

  /** for attachment form */
  const currentMeasureClassName: string = data.measure?.modelName;
  const mandatoryAttachmentKeys = useMemo(
    () => getMandatoryAttachmentKeys(commonAttachmentTypes, MandatoryAttachments.offer, currentMeasureClassName),
    [ currentMeasureClassName ]
  );
  const sortedAttachmentItems = useMemo(
    () => mandatorySort(commonAttachmentTypes, MandatoryAttachments.offer, currentMeasureClassName, t),
    [ currentMeasureClassName, t ]
  );

  const tabs: FormTabItem[] = useMemo(
    () => [
      {
        key: 'measure',
        label: 'tab:title:measure',
        content: (
          <MeasureForm
            data={ data }
            readOnlyMode={ readOnly }
            onMeasureChange={ onMeasureChange }
            isAmendment={ Boolean(data?.isAmendment) }
            isProposal={ isProposal || Boolean(data.versionNr && data.versionNr > 0) }
            measureName={ measureName }
            measureType={ measureType }
          />
        ),
      },
      {
        key: 'contacts',
        label: 'tab:title:contacts',
        content: <ContactsForm readOnlyMode={ readOnly } currentContacts={ currentContacts }/>
      },
      {
        key: 'environment',
        label: 'tab:title:environment',
        content: <EnvironmentForm data={ data } readOnlyMode={ readOnly }/>
      },
      {
        key: 'target',
        label: 'tab:title:achievement',
        content: <AchievementForm data={ data } readOnlyMode={ readOnly }/>
      },
      {
        key: 'formation',
        label: 'tab:title:formation',
        content: <FormationForm data={ data } formation={ data?.formation } readOnlyMode={ readOnly }/>,
      },
      {
        key: 'childProtection',
        label: 'tab:title:child-protection',
        content: <ChildProtectionForm data={ data } readOnlyMode={ readOnly }/>,
      },
      {
        key: 'monitoring',
        label: 'tab:title:monitoring',
        content: <MonitoringForm data={ data } readOnlyMode={ readOnly }/>
      },
      { key: 'costPlan', label: 'tab:title:cost-plan', content: <CostPlanForm readOnlyMode={ readOnly }/> },
      {
        key: 'attachments',
        label: 'tab:title:attachments',
        content: (
          <AttachmentForm
            data={ data }
            isReport={ false }
            readOnlyMode={ readOnly || Boolean(data.isAmendment) || isProposal }
            items={ sortedAttachmentItems }
            mandatoryKeys={ mandatoryAttachmentKeys }
            showDropDown={ !readOnly && currentMeasureClassName !== 'Measure' }
          />
        ),
      },
    ],
    [
      currentContacts,
      currentMeasureClassName,
      data,
      mandatoryAttachmentKeys,
      measureName,
      measureType,
      onMeasureChange,
      readOnly,
      sortedAttachmentItems,
    ]
  );

  const updateOffer = () => dispatch(offerActions.updateCurrentOffer(data));
  const onSave = () => {
    updateOffer();

    if (data.isAmendment) {
      dispatch(offerActions.saveCurrentOffer(StateType.amendmentCloud));
    } else if (isProposal || data.versionNr && data.versionNr > 0) {
      dispatch(offerActions.saveCurrentOffer(StateType.proposalUserCloud));
    } else {
      dispatch(offerActions.saveCurrentOffer(StateType.cloud));
    }
  };

  const onApproveButtonClick = () => {
    if (isAdmin) {
      setActiveDialog({
        isVisible: true,
        contacts: contacts.map(contact => copyContact(contact)),
        oldContacts: contacts,
      });
    } else {
      onApprove();
    }
  };

  const openRejectionDialog = () => {
    if (isAdmin) {
      setIsRejectionDialogOpen(true);
    } else {
      onReject();
    }
  };

  const onUploadClick = () => {
    if (data.measure?.name === null) {
      dispatch(notificationActions.showError(t('use-cases:new-assignment:root:measure-missing')));
      return;
    }

    if (formError) {
      setMissingFieldsDialogActive(true);
    } else {
      if (data.isAmendment) {
        dispatch(offerActions.saveCurrentOffer(StateType.amendmentCompleted));
      } else if (data.versionNr && data.versionNr > 0) {
        dispatch(offerActions.saveCurrentOffer(StateType.proposalUserCompleted));
      } else {
        dispatch(offerActions.saveCurrentOffer(StateType.completed));
      }
    }
  };

  const onReject = () => {
    onSave();
    if (isAdmin) {
      const newStatus: ApplicationNewState = { message: rejectionMessage, status: StateType.rejected };
      dispatch(offerActions.updateOfferStatus(newStatus));
      setIsRejectionDialogOpen(false);
    } else {
      const newStatus: ApplicationNewState = { message: rejectionMessage, status: StateType.proposalRejectedUser };
      dispatch(offerActions.updateOfferStatus(newStatus));
    }
  };

  const onCancelApproval = () => {
    setActiveDialog({
      isVisible: false,
      contacts: [],
      oldContacts: [],
    });
  };

  const onApprove = () => {
    if (isAdmin) {
      setContactsOnOffer(data, activeDialog.contacts);
      dispatch(offerActions.updateCurrentOffer(data));
      const newStatus: ApplicationNewState = { message: '', status: StateType.approved };
      dispatch(offerActions.updateOfferStatus(newStatus));
      onCancelApproval();
    } else {
      const newStatus: ApplicationNewState = { message: '', status: StateType.proposalInProgressMissio };
      dispatch(offerActions.updateOfferStatus(newStatus));
    }
  };

  const handleTitleChange = (title: string) => {
    const formError = isEmpty(title);
    const actionObject: Action = formError
      ? {
        subFormId: 'title',
        errors: { title: 'missing title' },
        values: {},
      }
      : {
        subFormId: 'title',
        errors: {},
        values: {},
      };
    formDispatch(actionObject);
    onTitleChange(title);
  };

  return (
    <>
      <FormHead
        mode={ readOnly ? 'review-admin' : 'edit' }
        title={ title }
        isReadonly={ readOnly }
        measureName={data.measure.name}
        showAdminButtons={ isAdmin && (data.state?.name === StateType.completed || data.state?.name === StateType.easydorError) }
        showUserButtons={ !isAdmin && data.state?.name === StateType.proposalMissio }
        isEasydorError={ data.state?.name === StateType.easydorError }
        titleDescription={ t('create-assignment') }
        onTitleChange={ handleTitleChange }
        onSave={ onSave }
        onUpload={ onUploadClick }
        onAccept={ onApproveButtonClick }
        onReject={ openRejectionDialog }
        isAssignment={ true }
      />
      <div className={ classes.tabContainer }>
        <Tabs variant="scrollable" scrollButtons="on" value={ activeTabIndex }
              onChange={ (_, tabIndex) => setActiveTabIndex(tabIndex) }>
          { tabs.map((tab, index) => (
            <FormTab
              key={ tab.key }
              disabled={ measureName == null && index !== activeTabIndex }
              hideIcon={ readOnly || (tab.key === 'attachments' && isProposal) }
              disableRipple
              label={ t(tab.label) }
              errorCount={ state[tab.key]?.errorCount ?? undefined }
            />
          )) }
        </Tabs>
        { tabs.map((tab, index) => (
          <Box key={ `${ tab.key }-${ title }` } sx={ { display: index !== activeTabIndex ? 'none' : 'initial' } }>
            { tab.content }
          </Box>
        )) }
        { isMissingFieldsDialogActive && (
          <DefaultDialog
            maxWidth="sm"
            isOpen={ true }
            children={ t('fill-mandatory-fields') }
            actionComponent={ <PrimaryButton
              onClick={ () => setMissingFieldsDialogActive(false) }>{ t('button:label:cancel') }</PrimaryButton> }
            title={ t('missing-fields:form:dialog-title') }
          />
        ) }
      </div>
      <ReasonDialog
        isOpen={ isRejectionDialogOpen }
        onCancelButtonClick={ () => setIsRejectionDialogOpen(false) }
        onSaveButtonClick={ onReject }
        content={ t('reject-dialog:content') }
        onChange={ event => {
          setRejectionMessage(event.target.value);
        } }
        value={ rejectionMessage }
      />
      <ApproveOfferDialog
        isOpen={ activeDialog.isVisible }
        onApprovalButtonClick={ () => {
          onApprove();
          setActiveDialog({
            isVisible: false,
            contacts: [],
            oldContacts: [],
          });
        } }
        onCancelButtonClick={ onCancelApproval }
        contacts={ contacts }
        contactLength={ contacts.length }
        offer={ data }
        referents={ referents }
      />
    </>
  );
};

export default NewAssignment;
