import { Button, DialogContentText } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OfferType } from '../../enums/OfferType';
import Offer from '../../sdk/com/apiomat/frontend/missio/Offer';
import { Link } from 'react-router-dom';
import DefaultDialog from './DefaultDialog';
import { OfferModes } from '../../enums/OfferModes';

export interface CopyAssignmentDialogProps {
  isOpen: boolean;
  offer: Offer;
  onCancelButtonClick: () => void;
}

const CopyAssignmentDialog = (props: CopyAssignmentDialogProps) => {
  const { isOpen, offer, onCancelButtonClick } = props;
  const { t } = useTranslation();

  return (
    <DefaultDialog
      maxWidth="sm"
      isOpen={ isOpen }
      actionComponent={
        <>
          <Button onClick={ onCancelButtonClick } color="primary">
            { t('button:label:cancel') }
          </Button>
          <Button
            color="primary"
            component={ Link }
            to={ `/assignment/${ offer.measureType === OfferType.mis ? 'mis' : 'mwi' }/${OfferModes.copy}/${ offer.ID }` }
          >
            { t('button:label:new-copy-assignment') }
          </Button>
        </>
      }
      title={ t('dialog:copy-assignment-title') }
      children={ <DialogContentText>{ t('dialog:copy-assignment-text') }</DialogContentText> }
    />
  );
};

export default CopyAssignmentDialog;
