import React, { useEffect } from 'react';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StateType } from '../../enums/StateType';
import { AppState } from '../../store';
import { makeStyles } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { Link } from 'react-router-dom';
import { ActualDeviationStatus } from '../../components/ActualDeviationStatus.tsx/ActualDeviationStatus';
import { Report, State } from '../../sdk/com/apiomat/frontend/missio';
import { reportActions } from '../../store/report';
import ReportIconStatus from '../../components/ReportIconStatus/ReportIconStatus';
import ActionButton from '@mui/material/Button';
import { CommentHistory } from '../../components/CommentHistory/CommentHistory';

interface TableReport {
  application: string;
  creatorProjectNr: string;
  deadline: string;
  status: string;
  completed: StateType;
  targetCompleted: boolean;
  commentHistory: State;
  actions: Report;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const statuses = [
    StateType.easydor,
    StateType.easydorInProgress,
    StateType.approved
  ];

  const query = statuses
    .map(status => `state.name=='${ status }'`)
    .join(' or ');

  useEffect(() => {
    dispatch(reportActions.loadReports(query));
  }, [ dispatch, query ]);

  const { reports, loadingReports, loadingCurrentReport } = useSelector((state: AppState) => state.report);

  const columns: Array<MUIDataTableColumnDef> = [
    {
      name: 'application',
      label: t('reports:header:application')
    },
    {
      name: 'creatorProjectNr',
      label: `${ t('reports:header:creator') }/${ t('reports:header:project-number') }`
    },
    {
      name: 'deadline',
      label: t('reports:header:deadline')
    },
    {
      name: 'status',
      label: t('reports:header:status')
    },
    {
      name: 'completed',
      label: t('reports:header:completed'),
      options: {
        customBodyRender: (value) => {
          return (
            <ReportIconStatus state={ value }></ReportIconStatus>
          );
        }
      }
    },
    {
      name: 'targetCompleted',
      label: t('reports:header:target-completed'),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ActualDeviationStatus hasDeviations={ value }/>
          );
        }
      }
    },
    {
      name: 'commentHistory',
      label: ' ',
      options: {
        customBodyRender: (value) => {
          return <CommentHistory state={ value } displayLastComment={ true }/>;
        }
      }
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = `action-menu-${ tableMeta.rowIndex }`;

          return (
            <div className={ classes.actionButton }>
              <ActionButton variant="outlined"
                            startIcon={ <Visibility/> }
                            color="primary"
                            component={ Link }
                            to={ `/tasks/report/new/${ value.ID }` }
              >
                { t('reports:actions:view') }
              </ActionButton>
            </div>
          );
        },
        filter: false,
        searchable: false,
        sort: false,
        viewColumns: false
      }
    }
  ];

  const data: Array<TableReport> = reports.map(report => {
    return {
      application: report.offer?.name,
      creatorProjectNr: report.offer?.projectNumber ? report.offer?.projectNumber : report.offer?.creator,
      deadline: report?.dueDate?.toLocaleDateString('de-DE'),
      status: t(`reports:status:${ report.state?.name }`),
      completed: report?.state?.name as StateType,
      targetCompleted: report.hasCostPlanDeviations === 1,
      commentHistory: report.state,
      actions: report
    };
  });

  return loadingReports === 'pending' || loadingCurrentReport === 'pending' ? (
    <LoadingIndicator/>
  ) : (
    <div className={ classes.container }>
      <MUIDataTable
        title={ t('all-reports') }
        data={ data }
        columns={ columns }
        options={ {
          selectableRows: 'none',
          print: false,
          download: false,
          filter: false,
          sort: false,
          viewColumns: false,
          search: false
        } }
      />
    </div>
  );
};
