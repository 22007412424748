import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, makeStyles } from '@material-ui/core';
import Contact from '../../sdk/com/apiomat/frontend/missio/Contact';
import DataGrid, { DataGridColumn } from '../DataGrid/DataGrid';
import { getContactName } from '../../utils/contact.utils';
import DropDown from '../Selection/DropDown';
import NavContactComponent from '../NavContact/NavContactComponent';

import DefaultDialog from './DefaultDialog';

import MReferent from '../../sdk/com/apiomat/frontend/missio/MReferent';
import Offer from '../../sdk/com/apiomat/frontend/missio/Offer';
import { DropDownItem } from '../Selection/DropDownItem';

const useStyles = makeStyles(() => ({
  container: {
    padding: '12px 12px 0 12px',
  },
  section: {
    padding: '12px',
  },
  referent: {
    padding: '12px',
    marginTop: '12px',
  },
  dropdown: {
    maxWidth: '50%',
    marginTop: '12px',
  },
}));

export interface ApproveOfferDialogProps {
  onCancelButtonClick: () => void;
  onApprovalButtonClick: (contacts: Contact[]) => void;
  isOpen: boolean;
  contacts: Contact[];
  contactLength: number;
  referents: MReferent[];
  offer: Offer;
}

const ApproveOfferDialog = (props: ApproveOfferDialogProps) => {
  const { isOpen, onCancelButtonClick, onApprovalButtonClick, contacts, contactLength, referents, offer } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isNavIdSaved, setIsNavIdSaved] = useState(new Array(contactLength).fill(true));
  const [selectedReferent, setSelectedReferent] = useState<string>('');
  const [dialogContacts, setDialogContacts] = useState<Contact[]>(contacts);

  const referentItems: DropDownItem[] = referents?.map(referent => ({
    key: `${referent.login}`,
    value: `${referent.firstName} ${referent.lastName}`,
  }));

  const toggleNavIdBox = (contact: Contact) => {
    const index = dialogContacts.indexOf(contact);
    isNavIdSaved[index] = !isNavIdSaved[index];
    setIsNavIdSaved([...isNavIdSaved]);
  };

  const isSaved = (contact: Contact) => {
    const index = dialogContacts.indexOf(contact);
    return isNavIdSaved[index];
  };

  const onCancelContactIdDialog = () => {
    setIsNavIdSaved(new Array(contactLength).fill(true));
    onCancelButtonClick();
  };

  const columns: DataGridColumn<Contact>[] = [
    { header: t('label:contact-table-name'), valueExtractor: getContactName },
    {
      header: t('contact:contact-id:dialog:column:address'),
      valueExtractor: contact => `${contact.place || ''} ${contact.place && contact.country ? ',' : ''} ${t(contact.country) || ''}`,
    },
    {
      header: t('contact:contact-id:dialog:column:navigation-id'),
      valueExtractor: contact => contact,
      component: contact => (
        <NavContactComponent {...{ contact, setDialogContacts, dialogContacts, isNavIdSaved, toggleNavIdBox, isSaved }} />
      ),
    },
  ];

  return (
    <DefaultDialog
      isOpen={isOpen}
      maxWidth={'md'}
      title={
        <div className={classes.container}>
          <Typography component="span" variant="h5">
            {t('approved-offer:form:dialog:title')}
          </Typography>
        </div>
      }
      children={
        /* if put children in components focus leaves navId input after one keystroke*/
        <>
          {/* contact navId section */}
          <div className={classes.section}>
            <Typography component="span" variant="h6">
              {t('tab:title:contacts')}
            </Typography>
            <Typography variant="body1">{t('contact:form:dialog:subtitle')}</Typography>
          </div>
          <DataGrid
            datasource={dialogContacts}
            columns={columns}
            emptyMessage={{ title: t('data-grid:empty-message-title'), component: '' }}
            headOneThird={true}
          />
          {/* referent section */}
          <div className={classes.referent}>
            <Typography component="span" variant="h6">
              {t('referent:dropdown:dialog:title')}
            </Typography>
            <Typography variant="body1">{t('referent:dropdown:dialog:subtitle')}</Typography>
            <div className={classes.dropdown}>
              <DropDown
                items={referentItems}
                selectedItemKey={selectedReferent}
                onSelectedItemChange={(value: string) => {
                  offer.referent = value;
                  setSelectedReferent(value);
                }}
              />
            </div>
          </div>
        </>
      }
      actionComponent={
        <>
          <Button onClick={onCancelContactIdDialog} color="primary">
            {t('button:label:cancel')}
          </Button>
          <Button onClick={() => onApprovalButtonClick(dialogContacts)} color="primary">
            {t('form-head:button:accept')}
          </Button>
        </>
      }
    />
  );
};

export default ApproveOfferDialog;
