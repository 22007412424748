import React from 'react';
import { Card, CardActions, CardContent, CardMedia, makeStyles } from '@material-ui/core';
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/index';
import { authActions } from '../store/auth';
import { useTranslation } from 'react-i18next';
import { EasyLogo, MissioLogo, MwiLogo } from '../components/Logo/Logo';
import CultureSelection from '../components/Localization/CultureSelection';
import { PrimaryButton } from '../components/Buttons/Buttons';
import { EmailTextField, PasswordTextField } from '../components/TextInputs/TextInputs';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import { object, string } from 'yup';

const useStyles = makeStyles(theme => ({
  fullHeightContainer: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.grey['50'],
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '20px',
  },
  divider: {
    marginBottom: 15,
  },
  cardContainer: {
    maxWidth: 350,
    minWidth: 300,
    backgroundColor: theme.palette.common.white,
  },
  cardMediaContainer: {
    paddingTop: 25
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  forgotPasswordLink: {
    '&.MuiLink-root': {
      paddingBottom: 5,
      paddingTop: 5,
      cursor: 'pointer',
      marginRight: 'auto',
      textDecorationColor: theme.palette.common.white,
      color: theme.palette.text.disabled,
      ...theme.typography.body2,
    }
  },
  loginButton: {
    margin: 5,
  },
  registerButton: {
    margin: 5,
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loading = useSelector((state: AppState) => state.auth.loading);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: object({
      email: string()
        .email(t('invalid_email_address'))
        .required(t('mandatory_field')),
      password: string().required(t('mandatory_field')),
    }),
    initialTouched: {
      email: false,
      password: false,
    },
    validateOnChange: true,
    onSubmit: values => {
      dispatch(authActions.loginWithCredentials({ userName: values.email, password: values.password }));
    },
  });

  /**
   * Convert email input to lowercase instantly - pure JS solution, so there is no need of applying any CSS rules in
   * order to have login credential in small letters.
   * @param change
   */
  const emailHandleChange = (change: React.ChangeEvent<HTMLTextAreaElement>) => {
    change.target.value = change.target.value.toLowerCase();
    return formik.handleChange(change);
  };

  return (
    <div className={ classes.fullHeightContainer }>
      <Card className={ classes.cardContainer }>
        <CardMedia className={ classes.cardMediaContainer }>
          <MissioLogo/>
          <div className={ classes.divider }/>
          <MwiLogo/>
          <div className={ classes.divider }/>
          <CardContent>
            <form onSubmit={ formik.handleSubmit }>
              <div className={ classes.container }>
                <EmailTextField
                  id="email"
                  name="email"
                  value={ formik.values.email }
                  onChange={ emailHandleChange }
                  onBlur={ () => formik.setFieldTouched('email') }
                  error={ formik.touched.email && Boolean(formik.errors.email) }
                  helperText={ formik.touched.email && formik.errors.email }
                />
                <PasswordTextField
                  id="password"
                  name="password"
                  value={ formik.values.password }
                  onChange={ formik.handleChange }
                  onBlur={ () => formik.setFieldTouched('password') }
                  error={ formik.touched.password && Boolean(formik.errors.password) }
                  helperText={ formik.touched.password && formik.errors.password }
                />
              </div>
              <CardActions className={ classes.buttonContainer }>
                <Link className={ classes.forgotPasswordLink } component={ RouterLink } to="/forgot-password">
                  { t('button:label:forgot-password') }
                </Link>
                <PrimaryButton type="submit" className={ classes.loginButton } fullWidth
                               disabled={ loading === 'pending' }>
                  { t('button:label:login') }
                </PrimaryButton>
                <PrimaryButton className={ classes.registerButton } fullWidth disabled={ false }
                               component={ RouterLink } to="/register">
                  { t('button:label:register') }
                </PrimaryButton>
              </CardActions>
            </form>
            <div className={ classes.divider }/>
            <CultureSelection/>
            <EasyLogo/>
          </CardContent>
        </CardMedia>
      </Card>
    </div>
  );
};
