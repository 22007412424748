import { ValidationCondition, ValidationType } from './OfferValidation';
import { isOfferTypeMis, isOfferTypeMwi } from '../guards/OfferTypeGuard';
import Conference from '../sdk/com/apiomat/frontend/missio/Conference';
import { MisMeasureTypes, MwiMeasureTypes } from '../enums/MeasureTypes';
import Media from '../sdk/com/apiomat/frontend/missio/Media';
import Publication from '../sdk/com/apiomat/frontend/missio/Publication';
import Vehicle from '../sdk/com/apiomat/frontend/missio/Vehicle';
import AcquisitionOfEquipment from '../sdk/com/apiomat/frontend/missio/AcquisitionOfEquipment';
import Property from '../sdk/com/apiomat/frontend/missio/Property';
import Apprenticeship from '../sdk/com/apiomat/frontend/missio/Apprenticeship';
import Report from '../sdk/com/apiomat/frontend/missio/Report';
import { getDateValue } from './ValidationConditions';
import Evaluation from '../sdk/com/apiomat/frontend/missio/Evaluation';
import MwiLibrary from '../sdk/com/apiomat/frontend/missio/MwiLibrary';
import MwiResearch from '../sdk/com/apiomat/frontend/missio/MwiResearch';
import MwiConference from '../sdk/com/apiomat/frontend/missio/MwiConference';
import MwiConferenceAttendance from '../sdk/com/apiomat/frontend/missio/MwiConferenceAttendance';
import MwiProfessorExchange from '../sdk/com/apiomat/frontend/missio/MwiProfessorExchange';
import MwiPublication from '../sdk/com/apiomat/frontend/missio/MwiPublication';
import MwiScholarship from '../sdk/com/apiomat/frontend/missio/MwiScholarship';
import MwiTranslation from '../sdk/com/apiomat/frontend/missio/MwiTranslation';
import { allAttachmentTypes, commonAttachmentTypes, mwiAttachmentTypes } from '../value-maps/AttachmentTypes';
import i18n from '../utils/i18n';
import MwiResearchStudy from '../sdk/com/apiomat/frontend/missio/MwiResearchStudy';
import MwiResearchInstitution from '../sdk/com/apiomat/frontend/missio/MwiResearchInstitution';
import MwiResearchPerson from '../sdk/com/apiomat/frontend/missio/MwiResearchPerson';
import MwiPublicationPrint from '../sdk/com/apiomat/frontend/missio/MwiPublicationPrint';
import MwiPublicationElectronic from '../sdk/com/apiomat/frontend/missio/MwiPublicationElectronic';
import TargetGroup from '../sdk/com/apiomat/frontend/missio/TargetGroup';
import PastoralPlan from '../sdk/com/apiomat/frontend/missio/PastoralPlan';
import CollectiveScholarshipCongregation from '../sdk/com/apiomat/frontend/missio/CollectiveScholarshipCongregation';
import CollectiveScholarshipInstitution from '../sdk/com/apiomat/frontend/missio/CollectiveScholarshipInstitution';
import Upkeep from '../sdk/com/apiomat/frontend/missio/Upkeep';
import MwiSpecialProject from '../sdk/com/apiomat/frontend/missio/MwiSpecialProject';

export const generalReportValidationConditions = (report: Report): ValidationCondition[] => {
  const offer = report.offer;
  const measureName = offer.measure.name;

  if (isOfferTypeMis(offer)) {
    switch (measureName) {
      case MisMeasureTypes.realEstate:
        return getReportPropertyValidationConditions(report);
      default:
        return [];
    }
  } else if (isOfferTypeMwi(offer)) {
    switch (measureName) {
      case MwiMeasureTypes.library:
        return getReportLibraryValidationConditions(report);
      case MwiMeasureTypes.publicationPrint:
        return getReportMwiPublicationValidationConditions(report);
      case MwiMeasureTypes.publicationElectronic:
        return getReportMwiPublicationValidationConditions(report);
      case MwiMeasureTypes.scholarship:
        return getReportMwiScholarshipValidationConditions(report);
      case MwiMeasureTypes.specialProject:
        return getReportMwiSpecialProjectValidationConditions(report);
      case MwiMeasureTypes.conference:
        return getReportMwiConferenceValidationConditions(report);
      default:
        return [];
    }
  } else {
    return [];
  }
};

export const reportMeasureValidationConditions = (report: Report): ValidationCondition[] => {
  const offer = report.offer;
  const measureName = offer.measure.name;
  const measure = report.measure;
  if (isOfferTypeMis(offer)) {
    switch (measureName) {
      case MisMeasureTypes.realEstate:
        return getPropertyValidationConditions(measure as Property);
      case MisMeasureTypes.pastoralProgram:
        return getPastoralProgramValidationConditions(measure as PastoralPlan);
      case MisMeasureTypes.equipmentPurchase:
        return getEquipmentPurchaseValidationConditions(measure as AcquisitionOfEquipment);
      case MisMeasureTypes.conference:
        return getConferenceValidationConditions(measure as Conference);
      case MisMeasureTypes.media:
        return getMediaValidationConditions(measure as Media);
      case MisMeasureTypes.publication:
        return getPublicationValidationConditions(measure as Publication);
      case MisMeasureTypes.trainingPrograms:
        return getTrainingProgramValidationConditions(measure as Apprenticeship);
      case MisMeasureTypes.vehicle:
        return getVehicleValidationConditions(measure as Vehicle);
      case MisMeasureTypes.evaluation:
        return getEvaluationValidationConditions(measure as Evaluation);
      case MisMeasureTypes.scholarshipCongregation:
        return getCollectiveScholarshipCongregationValidationConditions(measure as CollectiveScholarshipCongregation);
      case MisMeasureTypes.scholarshipInstitute:
        return getCollectiveScholarshipInstitutionValidationConditions(measure as CollectiveScholarshipInstitution);
      case MisMeasureTypes.maintenance:
        return getUpkeepValidationConditions(measure as Upkeep);
      default:
        return [];
    }
  } else if (isOfferTypeMwi(offer)) {
    switch (measureName) {
      case MwiMeasureTypes.library:
        return getLibraryValidationConditions(measure as MwiLibrary);
      case MwiMeasureTypes.researchStudy:
        return getResearchValidationConditions(measure as MwiResearchStudy);
      case MwiMeasureTypes.researchPerson:
        return getResearchValidationConditions(measure as MwiResearchPerson);
      case MwiMeasureTypes.researchInstitution:
        return getResearchValidationConditions(measure as MwiResearchInstitution);
      case MwiMeasureTypes.conference:
        return getMwiConferenceValidationConditions(measure as MwiConference);
      case MwiMeasureTypes.conferenceParticipation:
        return getMwiConferenceParticipationValidationConditions(measure as MwiConferenceAttendance);
      case MwiMeasureTypes.facultyExchange:
        return getMwiFacultyExchangeValidationConditions(measure as MwiProfessorExchange);
      case MwiMeasureTypes.publicationElectronic:
        return getMwiPublicationElectronicValidationConditions(measure as MwiPublicationElectronic);
      case MwiMeasureTypes.publicationPrint:
        return getMwiPublicationPrintValidationConditions(measure as MwiPublicationPrint);
      case MwiMeasureTypes.scholarship:
        return getMwiScholarshipValidationConditions(measure as MwiScholarship);
      case MwiMeasureTypes.translation:
        return getMwiTranslationValidationConditions(measure as MwiTranslation);
      case MwiMeasureTypes.specialProject:
        return getMwiSpecialProjectValidationConditions(measure as MwiSpecialProject);
      default:
        return [];
    }
  } else {
    return [];
  }
};

const getTargetGroupValidationConditions = (targetGroup: TargetGroup) => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:achievement-description',
    value: targetGroup.targetDescription,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:group-description',
    value: targetGroup.targetGroupDescription,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:group-participation',
    value: targetGroup.targetGroupParticipation,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:stakeholder',
    value: targetGroup.stakeholder,
  });

  return validationConditions;
};

const getLibraryValidationConditions = (library: MwiLibrary): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-start',
    value: getDateValue(library.start),
  });
  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-end',
    value: getDateValue(library.end),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:library:number-of-books-to-be-purchased',
    value: library.numberOfBooksToPurchase,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:library:book-seller-info',
    value: library.nameAndAddressOfSeller,
  });

  return validationConditions;
};

const getReportLibraryValidationConditions = (report: Report): ValidationCondition[] => {
  return [
    {
      discriminator: ValidationType.fieldNotEmpty,
      key: 'input:label:sustainability',
      value: report.monitoring.sustained,
    },
  ];
};

const getResearchValidationConditions = (research: MwiResearch): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-start',
    value: getDateValue(research.start),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-end',
    value: getDateValue(research.end),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:research:topic-research',
    value: research.subjectWork,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:research:topic-meaning-and-relation',
    value: research.topicMeaning,
  });

  return validationConditions;
};

const getPastoralProgramValidationConditions = (measure: PastoralPlan): ValidationCondition[] => {
  return [
    {
      discriminator: ValidationType.fieldNotEmpty,
      key: 'input:label:measure:measure-description',
      value: measure.measureDescription,
    },
  ];
};

const getMwiConferenceValidationConditions = (conference: MwiConference): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-start',
    value: getDateValue(conference.start),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-end',
    value: getDateValue(conference.end),
  });

  validationConditions.push({
    discriminator: ValidationType.biggerThanZero,
    key: 'input:label:measure:mwi:conference:number-of-participants',
    value: conference.numberOfParticipants,
  });

  return validationConditions;
};

const getReportMwiConferenceValidationConditions = (report: Report): ValidationCondition[] => {
  return [
    {
      discriminator: ValidationType.fieldNotEmpty,
      key: 'input:label:examination-of-goals-achievement',
      value: report.monitoring.targetCheck,
    },
  ];
};

const getMwiConferenceParticipationValidationConditions = (conferenceParticipation: MwiConferenceAttendance): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-start',
    value: getDateValue(conferenceParticipation.start),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-end',
    value: getDateValue(conferenceParticipation.end),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:conference-participation:meaning-of-the-conference',
    value: conferenceParticipation.topicMeaning,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:conference-participation:relevance-of-participation',
    value: conferenceParticipation.relevanceAttendance,
  });

  return validationConditions;
};

const getMwiFacultyExchangeValidationConditions = (facultyExchange: MwiProfessorExchange): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-start',
    value: getDateValue(facultyExchange.start),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-end',
    value: getDateValue(facultyExchange.end),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:faculty-exchange:number-of-students',
    value: facultyExchange.studentsCount,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:faculty-exchange:teaching-topic',
    value: facultyExchange.topic,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:faculty-exchange:meaning-of-apprenticeship',
    value: facultyExchange.topicMeaning,
  });

  return validationConditions;
};

const getMwiPublicationPrintValidationConditions = (publication: MwiPublicationPrint): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = getMwiPublicationValidationConditions(publication);

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:publication:circulation',
    value: publication.amount,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:publication:number-of-pages',
    value: publication.pageCount,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:publication:marketing-strategy',
    value: publication.marketingStrategy,
  });

  return validationConditions;
};

const getMwiPublicationElectronicValidationConditions = (publication: MwiPublicationElectronic): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = getMwiPublicationValidationConditions(publication);

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:publication:website',
    value: publication.website,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:publication:operator-of-the-website',
    value: publication.websiteOperator,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:publication:marketing',
    value: publication.marketing,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:publication:specify-characters-including-spaces',
    value: publication.characterCount,
  });

  return validationConditions;
};

const getMwiPublicationValidationConditions = (publication: MwiPublication): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-start',
    value: getDateValue(publication.start),
  });
  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-end',
    value: getDateValue(publication.end),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:publication:book-title',
    value: publication.bookTitle,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:publication:participation-author-sales',
    value: publication.authorsShare,
  });

  return validationConditions;
};

const getReportMwiPublicationValidationConditions = (report: Report): ValidationCondition[] => {
  return [
    {
      discriminator: ValidationType.fieldNotEmpty,
      key: 'input:label:examination-of-goals-achievement',
      value: report.monitoring.targetCheck,
    },
  ];
};

const getMwiScholarshipValidationConditions = (scholarship: MwiScholarship) => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-start',
    value: getDateValue(scholarship.start),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-end',
    value: getDateValue(scholarship.end),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:measure-description',
    value: scholarship.measureDescription,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:scholarship:scholarship-description',
    value: scholarship.studyDescription,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:scholarship:university',
    value: scholarship.university,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:scholarship:subject',
    value: scholarship.subject,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:scholarship:approached-graduation',
    value: scholarship.graduation,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:scholarship:thesis-title',
    value: scholarship.thesisTitle,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:scholarship:supervisor',
    value: scholarship.supervisor,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:scholarship:topic-work',
    value: scholarship.thesisTopic,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:scholarship:planned-pastoral-side-job',
    value: scholarship.pastoralSideActivities,
  });

  return validationConditions;
};

const getReportMwiScholarshipValidationConditions = (report: Report): ValidationCondition[] => {
  return [
    {
      discriminator: ValidationType.fieldNotEmpty,
      key: 'input:label:examination-of-goals-achievement',
      value: report.monitoring.targetCheck,
    },
  ];
};

const getMwiTranslationValidationConditions = (translation: MwiTranslation) => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-start',
    value: getDateValue(translation.start),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-end',
    value: getDateValue(translation.end),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:translations:topic-work',
    value: translation.topic,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mwi:translations:meaning-local-church',
    value: translation.relevanceToLocalChurch,
  });

  return validationConditions;
};

const getEvaluationValidationConditions = (evaluation: Evaluation): ValidationCondition[] => {
  return [
    {
      discriminator: ValidationType.fieldNotEmpty,
      key: 'info:measure:mwi:evaluation:evaluation-description',
      value: evaluation.evaluationDescription,
    },
  ];
};

const getConferenceValidationConditions = (conference: Conference): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:measure-description',
    value: conference.measureDescription,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:conference-location',
    value: conference.location,
  });

  return validationConditions;
};

const getEquipmentPurchaseValidationConditions = (equipmentPurchaseMeasure: AcquisitionOfEquipment): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:equipment-description',
    value: equipmentPurchaseMeasure.equipmentDescription,
  });
  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:equipment-cost',
    value: equipmentPurchaseMeasure.equipmentUpkeepCosts,
  });

  return validationConditions;
};

const getMediaValidationConditions = (mediaMeasure: Media): ValidationCondition[] => {
  return [
    {
      discriminator: ValidationType.fieldNotEmpty,
      key: 'input:label:measure:measure-description',
      value: mediaMeasure.measureDescription,
    },
  ];
};

const getPublicationValidationConditions = (publicationMeasure: Publication): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:measure-description',
    value: publicationMeasure.measureDescription,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:publication-sales',
    value: publicationMeasure.salesInformation,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:publication-author',
    value: publicationMeasure.authorInformation,
  });

  validationConditions.push({
    discriminator: ValidationType.biggerThanZero,
    key: 'input:label:measure:publication-circulation',
    value: publicationMeasure.numberOfCopies,
  });

  return validationConditions;
};

const getPropertyValidationConditions = (realEstate: Property): ValidationCondition[] => {
  let validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-start',
    value: getDateValue(realEstate.start),
  });
  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-end',
    value: getDateValue(realEstate.end),
  });

  validationConditions.push({
    discriminator: ValidationType.biggerThanZero,
    key: 'input:label:measure:mis:real-estate:square-metre-of-building',
    value: realEstate.squareMetersOfTheProperty,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mis:real-estate:construction-execution',
    value: realEstate.constructionExecution,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:mis:real-estate:construction-state',
    value: realEstate.constructionState,
  });

  return validationConditions;
};

const getReportPropertyValidationConditions = (report: Report): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = getTargetGroupValidationConditions(report.targetGroup);

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:examination-of-goals-achievement',
    value: report.monitoring.targetCheck,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:sustainability',
    value: report.monitoring.sustained,
  });

  return validationConditions;
};

const getTrainingProgramValidationConditions = (trainingMeasure: Apprenticeship): ValidationCondition[] => {
  return [
    {
      discriminator: ValidationType.fieldNotEmpty,
      key: 'input:label:measure:training-objective',
      value: trainingMeasure.examination,
    },
  ];
};

const getVehicleValidationConditions = (vehicleMeasure: Vehicle): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:vehicle-description',
    value: vehicleMeasure.description,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:vehicle-use',
    value: vehicleMeasure.usage,
  });

  return validationConditions;
};

const getMwiSpecialProjectValidationConditions = (measure: MwiSpecialProject): ValidationCondition[] => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-start',
    value: getDateValue(measure.start),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:planned-project-end',
    value: getDateValue(measure.end),
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:measure:measure-description',
    value: measure.measureDescription,
  });

  return validationConditions;
};

const getReportMwiSpecialProjectValidationConditions = (report: Report) => {
  const validationConditions: ValidationCondition[] = [];

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:environment-description',
    value: report.environment,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:examination-of-goals-achievement',
    value: report.monitoring.targetCheck,
  });

  validationConditions.push({
    discriminator: ValidationType.fieldNotEmpty,
    key: 'input:label:sustainability',
    value: report.monitoring.sustained,
  });

  return validationConditions;
};
const getCollectiveScholarshipCongregationValidationConditions = (measure: CollectiveScholarshipCongregation): ValidationCondition[] => {
  return [
    {
      discriminator: ValidationType.fieldNotEmpty,
      key: 'input:label:measure:measure-description',
      value: measure.measureDescription,
    },
  ];
};

const getCollectiveScholarshipInstitutionValidationConditions = (measure: CollectiveScholarshipInstitution): ValidationCondition[] => {
  return [
    {
      discriminator: ValidationType.fieldNotEmpty,
      key: 'input:label:measure:measure-description',
      value: measure.measureDescription,
    },
  ];
};

const getUpkeepValidationConditions = (measure: Upkeep): ValidationCondition[] => {
  return [
    {
      discriminator: ValidationType.fieldNotEmpty,
      key: 'input:label:measure:measure-description',
      value: measure.measureDescription,
    },
  ];
};

export const reportAttachmentValidationConditions = (report: Report) => {
  const validationConditions: ValidationCondition[] = [];

  let mandatoryAttachmentTypes = commonAttachmentTypes
    .filter(attachment => attachment.mandatoryForReportMeasures?.includes(report?.measure?.modelName))
    .map(filtered => filtered.key);

  if (isOfferTypeMwi(report.offer)) {
    mandatoryAttachmentTypes = [
      ...mandatoryAttachmentTypes,
      ...mwiAttachmentTypes
        .filter(attachment => attachment.mandatoryForReportMeasures?.includes(report?.measure?.modelName))
        .map(filtered => filtered.key),
    ];
  }

  const reportAttachmentTypes = report.attachments.map(attachment => attachment.attachmentType);

  mandatoryAttachmentTypes.forEach(mandatoryType => {
    if (!reportAttachmentTypes.includes(mandatoryType)) {
      validationConditions.push({
        discriminator: ValidationType.fieldNotEmpty,
        key: `${i18n.t('attachments:mandatory')} ${i18n.t(
          allAttachmentTypes.find(attachment => attachment.key === mandatoryType)?.value || ''
        )}`,
        value: '',
      });
    }
  });
  return validationConditions;
};
