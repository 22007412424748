import React, { useEffect, useState } from 'react';
import { ListItemIcon, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReasonDialog from '../../components/Dialogs/ReasonDialog';
import ApproveOfferDialog from '../Dialogs/ApproveOfferDialog';
import { ApplicationNewState } from '../../utils/application-state';
import { getFilledContactsFromOffer, setContactsOnOffer } from '../../utils/offer.utils';
import { copyContact } from '../../utils/contact.utils';
import { offerActions } from '../../store/offers';
import { StateType } from '../../enums/StateType';
import { referentActions } from '../../store/referent';
import { AppState } from '../../store';
import Contact from '../../sdk/com/apiomat/frontend/missio/Contact';
import { MenuItem } from '@mui/material';
import { Close, Done, Visibility } from '@material-ui/icons';
import { Flaky } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { OfferType } from '../../enums/OfferType';
import ActionMenu from './ActionMenu';

interface ActionMenuOpenAssignmentsProps {
  menuId: string;
  offer: any;
  isVisible: boolean;
  disabled: boolean;
}

export default (props: ActionMenuOpenAssignmentsProps) => {
  const { menuId, offer, isVisible, disabled } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((state: AppState) => state.auth);
  const { referents } = useSelector((state: AppState) => state.referent);
  const [ isRejectionDialogOpen, setIsRejectionDialogOpen ] = useState(false);
  const [ rejectionMessage, setRejectionMessage ] = useState('');
  const [ isApprovalDialogOpen, setIsApprovalDialogOpen ] = useState<boolean>(false);

  useEffect(() => {
    dispatch(referentActions.loadReferents());
  }, [ dispatch ]);

  const contacts = getFilledContactsFromOffer(offer);

  const onApprove = (contacts: Contact[]) => {
    if (isAdmin) {
      setContactsOnOffer(offer, contacts);
      dispatch(offerActions.updateCurrentOffer(offer));
      const newStatus: ApplicationNewState = { message: '', status: StateType.approved };
      dispatch(offerActions.updateOfferStatus(newStatus));
      cancelApproval();
    } else {
      dispatch(offerActions.updateCurrentOffer(offer));
      const newStatus: ApplicationNewState = { message: '', status: StateType.proposalInProgressMissio };
      dispatch(offerActions.updateOfferStatus(newStatus));
    }
  };

  const openApprovalDialog = () => {
    if (isAdmin) {
      setIsApprovalDialogOpen(true);
    } else {
      onApprove(null);
    }
  };

  const cancelApproval = () => {
    setIsApprovalDialogOpen(false);
  };

  const openRejectionDialog = () => {
    if (isAdmin) {
      setIsRejectionDialogOpen(true);
    } else {
      onReject();
    }
  };

  const onReject = () => {
    if (isAdmin) {
      dispatch(offerActions.updateCurrentOffer(offer));
      const newStatus: ApplicationNewState = { message: rejectionMessage, status: StateType.rejected };
      dispatch(offerActions.updateOfferStatus(newStatus));
    } else {
      dispatch(offerActions.updateCurrentOffer(offer));
      const newStatus: ApplicationNewState = { message: rejectionMessage, status: StateType.proposalRejectedUser };
      dispatch(offerActions.updateOfferStatus(newStatus));
    }
  };

  const cancelRejection = () => {
    setIsRejectionDialogOpen(false);
  };

  return (
    <>
      <ActionMenu menuId={ menuId } icon={ <Flaky/> }>
        <MenuItem component={ Link }
                  to={ `/assignment/${ offer.measureType === OfferType.mis ? 'mis' : 'mwi' }/${ offer.ID }` }>
          <ListItemIcon>
            <Visibility/>
          </ListItemIcon>
          { t('all-assignment:actions:view') }
        </MenuItem>
        { isVisible &&
          <MenuItem onClick={ () => openApprovalDialog() }
                    disabled={ disabled }>
            <ListItemIcon>
              <Done/>
            </ListItemIcon>
            { t('all-assignment:actions:approve') }
          </MenuItem>
        }
        { isVisible &&
          <MenuItem onClick={ () => openRejectionDialog() }
                    disabled={ disabled }>
            <ListItemIcon>
              <Close/>
            </ListItemIcon>
            { t('all-assignment:actions:reject') }
          </MenuItem>
        }
      </ActionMenu>
      <ReasonDialog
        isOpen={ isRejectionDialogOpen }
        onCancelButtonClick={ cancelRejection }
        onSaveButtonClick={ onReject }
        content={ t('reject-dialog:content') }
        onChange={ event => {
          setRejectionMessage(event.target.value);
        } }
        value={ rejectionMessage }
      />
      <ApproveOfferDialog
        key={offer.ID} // for some reason instances from the first page of the table this is used in were reused
        isOpen={ isApprovalDialogOpen }
        onApprovalButtonClick={ contacts => onApprove(contacts) }
        onCancelButtonClick={ cancelApproval }
        contacts={ contacts.map(contact => copyContact(contact)) }
        contactLength={ contacts.length }
        referents={ referents }
        offer={ offer }
      />
    </>
  );
};
