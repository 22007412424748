import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { notificationActions } from './notification';
import Offer from '../sdk/com/apiomat/frontend/missio/Offer';
import { AppState } from '.';
import i18n from '../utils/i18n';
import { OfferType } from '../enums/OfferType';
import { StateType } from '../enums/StateType';
import {
  createOfferCopy,
  getFilledContactsFromOffer,
  initOffer,
  loadReferencedData,
  prepareOfferForSaving,
  saveOffer,
  updateOfferAttachments,
  updateOfferStatus,
} from '../utils/offer.utils';
import Contact from '../sdk/com/apiomat/frontend/missio/Contact';
import { push } from 'connected-react-router';
import { createTransform } from 'redux-persist';
import { offerFromJson, offerToJson } from '../utils/transforms';
import { offlineActions } from './offline';
import { v4 as uuid } from 'uuid';
import MUser from '../sdk/com/apiomat/frontend/missio/MUser';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import { ApplicationNewState } from '../utils/application-state';
import { LoadReferencedDataResult } from '../utils/load-references.utils';

/** STATE */
export interface OfferState {
  offers: Offer[];
  incompleteOffers: { [id: string]: string[] | null };
  currentOffer: { offer: Offer } | null;
  currentContacts: ContactObj[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed' | 'loading-more';
  loadingOffer: 'idle' | 'pending' | 'succeeded' | 'failed';
  offerCount: number;
  offset: number;
}

const initialState: OfferState = {
  offers: [],
  incompleteOffers: {},
  currentOffer: null,
  currentContacts: [],
  loading: 'idle',
  loadingOffer: 'idle',
  offerCount: 0,
  offset: 0,
};

/** TYPES */
export interface ContactObj {
  contactObject: Contact;
}

/** SLICE */
const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    loadOffers: (state, _action: PayloadAction<string>) => {
      state.loading = 'pending';
    },
    loadOffersSuccess: (state, action: PayloadAction<Offer[]>) => {
      state.loading = 'succeeded';
      state.offers = action.payload;
    },
    loadMoreOffers: (state, _action: PayloadAction<string>) => {
      state.loading = 'loading-more';
    },
    loadMoreOffersSuccess: (state, action: PayloadAction<Offer[]>) => {
      state.loading = 'succeeded';
      const offers = action.payload;
      state.offers = [...state.offers, ...offers];
    },
    loadOffersFailure: (state) => {
      state.loading = 'failed';
    },
    loadCurrentOffer: (state, _action: PayloadAction<string>) => {
      state.loading = 'pending';
    },
    loadCurrentOfferSuccess: (state, action: PayloadAction<Offer>) => {
      state.loading = 'succeeded';

      const offer = action.payload;
      state.currentOffer = { offer };

      state.currentContacts = getFilledContactsFromOffer(offer).map((el) => ({ contactObject: el }));
    },
    loadCurrentOfferFailure: (state) => {
      state.loading = 'failed';
    },
    setIncompleteOffers: (state, action: PayloadAction<LoadReferencedDataResult[]>) => {
      const data = action.payload.reduce((acc, curr) => {
        acc[curr.id] = curr.failures.length > 0 ? curr.failures : null;
        return acc;
      }, {});

      state.incompleteOffers = { ...state.incompleteOffers, ...data };
    },
    createNewCurrentOffer: (state, action: PayloadAction<OfferType>) => {
      state.currentOffer = { offer: initOffer(action.payload) };
      state.currentContacts = [];
    },
    loadOrCreateChangeOffer: (state, action: PayloadAction<string>) => {
      state.loading = 'pending';
    },
    loadAndCopyCurrentOffer: (state, action: PayloadAction<string>) => {
      state.loading = 'pending';
    },
    updateCurrentOffer: (state, action: PayloadAction<Offer>) => {
      state.currentOffer = { offer: action.payload };
    },
    updateCurrentContacts: (state, action: PayloadAction<Contact[]>) => {
      state.currentContacts = action.payload.map((el) => ({ contactObject: el }));
    },
    saveCurrentOffer: (state, _action: PayloadAction<StateType>) => {
      state.loadingOffer = 'pending';
    },
    saveCurrentOfferSuccess: (state, action: PayloadAction<Offer>) => {
      const offer = action.payload;
      state.loadingOffer = 'succeeded';
      state.offers = [...state.offers.filter((el) => el.ID !== offer.ID), offer];
    },
    saveCurrentOfferFailure: (state) => {
      state.loadingOffer = 'failed';
    },
    updateOfferStatus: (state, _action: PayloadAction<ApplicationNewState>) => {
      state.loadingOffer = 'pending';
    },
    updateOfferStatusSuccess: (state, action: PayloadAction<Offer>) => {
      const offer = action.payload;
      state.loadingOffer = 'succeeded';
      state.offers = [...state.offers.filter((el) => el.ID !== offer.ID), offer];
    },
    updateOfferStatusFailure: (state) => {
      state.loadingOffer = 'failed';
    },
    updateOfferAttachments: (state) => {
      state.loadingOffer = 'pending';
    },
    updateOfferAttachmentsSuccess: (state, action: PayloadAction<Offer>) => {
      const offer = action.payload;
      state.loadingOffer = 'succeeded';
      state.offers = [...state.offers.filter((el) => el.ID !== offer.ID), offer];
    },
    updateOfferAttachmentsFailure: (state) => {
      state.loadingOffer = 'failed';
    },
    deleteOffer: (state, _action: PayloadAction<Offer>) => {
      state.loadingOffer = 'pending';
    },
    deleteOfferSuccess: (state, action: PayloadAction<string>) => {
      state.loadingOffer = 'succeeded';
      state.offerCount -= 1;
      state.offers = state.offers.filter((offer) => offer.ID !== action.payload);
    },
    deleteOfferFailure: (state) => {
      state.loadingOffer = 'failed';
    },
    updateCount: (state, action: PayloadAction<number>) => {
      state.offerCount = action.payload;
    },
  },
});

export const offerActions = offerSlice.actions;
export const offerReducer = offerSlice.reducer;

/** SAGAS */
function* onLoadOffers(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const query = action.payload || '';

  const localOffers: Offer[] = yield select((state: AppState) => state.offer.offers);
  if (isOnline === false) {
    yield put(offerActions.loadOffersSuccess(localOffers || []));
    return;
  }

  let offerCount: number = yield select((state: AppState) => state.offer.offerCount);
  try {
    offerCount = yield call(() => Offer.getOffersCount(query));
    yield put(offerActions.updateCount(offerCount));

    const offers: Offer[] = yield call(() => Offer.getOffers(`${query} order by lastModifiedAt DESC`));

    const { isAdmin } = yield select((state: AppState) => state.auth);
    if (!isAdmin) {
      const refDataResults = yield call(() => Promise.all(offers.map(offer => loadReferencedData(offer))));
      yield put(offerActions.setIncompleteOffers(refDataResults));

      // FIXME: why is this only done for the first page and not in the loadMore or loadCurrent sagas?
      for (const offer of offers) {
        if (offer.state.name === StateType.easydorRejected) {
          try {
            yield call(() => offer.loadRejectionFile());
          } catch (error) {}
        }
      }
    }

    yield put(offerActions.loadOffersSuccess(offers));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(offerActions.loadOffersFailure());
  }
}

// FIXME: dead code as far as i can tell
function* onLoadMoreOffers(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const query = action.payload || '';

  const localOffers: Offer[] = yield select((state: AppState) => state.offer.offers);
  if (isOnline === false) {
    yield put(offerActions.loadMoreOffersSuccess(localOffers || []));
    return;
  }

  let offerCount: number = yield select((state: AppState) => state.offer.offerCount);
  try {
    if (!offerCount) {
      offerCount = yield call(() => Offer.getOffersCount(query));
      yield put(offerActions.updateCount(offerCount));
    }

    const offers: Offer[] = yield call(() =>
      Offer.getOffers(`${query} offset ${localOffers.length} order by lastModifiedAt DESC limit 15`)
    );

    /* due to custom role check we won't get the exact count */
    if (!offers.length) {
      yield put(offerActions.updateCount(localOffers.length));
    }

    const { isAdmin } = yield select((state: AppState) => state.auth);
    if (!isAdmin) {
      const refDataResults = yield call(() => Promise.all(offers.map(offer => loadReferencedData(offer))));
      yield put(offerActions.setIncompleteOffers(refDataResults));
    }
    yield put(offerActions.loadMoreOffersSuccess(offers));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(offerActions.loadOffersFailure());
  }
}

function* onLoadCurrentOffer(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const id = action.payload;

  if (isOnline === false) {
    const offers: Offer[] = yield select((state: AppState) => state.offer.offers);
    yield put(offerActions.loadCurrentOfferSuccess(offers.find((el) => el.ID === id)));
    return;
  }

  try {
    const offer: Offer = new Offer();
    const href = `${Datastore.Instance.createModelHref(offer)}/${id}`;

    yield call(() => offer.load(href));
    if (offer) {
      yield call(() => offer.loadMeasure());
      yield call(() => offer.loadAttachments());
      yield call(() => offer.loadCostPlans());

      yield put(offerActions.loadCurrentOfferSuccess(offer));
    } else {
      yield put(notificationActions.showError('No entry found'));
      yield put(offerActions.loadCurrentOfferFailure());
    }
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(offerActions.loadCurrentOfferFailure());
  }
}

function* onLoadOrCreateChangeOffer(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const oldOfferId = action.payload;

  if (isOnline === false) {
    const offers: Offer[] = yield select((state: AppState) => state.offer.offers);
    const offer = offers.find((el) => el.ID === oldOfferId);
    if (offer.hasAmendment) {
      yield put(offerActions.loadCurrentOffer(offer.amendment.ID));
    } else {
      return;
    }
  }

  try {
    const offer: Offer = new Offer();
    const href = `${Datastore.Instance.createModelHref(offer)}/${oldOfferId}`;

    yield call(() => offer.load(href));
    if (offer.hasAmendment) {
      yield call(() => offer.loadAmendment());
      yield put(offerActions.loadCurrentOffer(offer.amendment.ID));
    } else {
      const contacts: ContactObj[] = yield select((state: AppState) => state.offer.currentContacts);
      // create proposal
      const proposalOfferId = yield call(() => createOfferCopy(offer, contacts, StateType.proposalUserCloud, true));
      yield put(offerActions.loadCurrentOffer(proposalOfferId));
    }
  } catch (error) {
    yield put(notificationActions.showError(error));
  }
}

/**
 * Keep amendment functionality, because there could be some old offers, which user wants to copy data from.
 * @param action
 */
function* onLoadAndCopyCurrentOffer(action: PayloadAction<string>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const oldOfferId = action.payload;

  if (isOnline === false) {
    const offers: Offer[] = yield select((state: AppState) => state.offer.offers);
    const offer = offers.find((el) => el.ID === oldOfferId);
    if (offer.hasAmendment) {
      yield put(offerActions.loadCurrentOffer(offer.amendment.ID));
    } else {
      return;
    }
  }

  try {
    const offer: Offer = new Offer();
    const href = `${Datastore.Instance.createModelHref(offer)}/${oldOfferId}`;

    yield call(() => offer.load(href));
    if (offer.hasAmendment) {
      yield call(() => offer.loadAmendment());
      yield put(offerActions.loadCurrentOffer(offer.amendment.ID));
    } else {
      const contacts: ContactObj[] = yield select((state: AppState) => state.offer.currentContacts);
      const copiedOfferId = yield call(() => createOfferCopy(offer, contacts, StateType.cloud, false));
      yield put(offerActions.loadCurrentOffer(copiedOfferId));
    }
  } catch (error) {
    yield put(notificationActions.showError(error));
  }
}

function* onUpdateOfferStatus(action: PayloadAction<ApplicationNewState>) {
  const offer: Offer = yield select((state: AppState) => state.offer.currentOffer.offer);
  const newStatus = action.payload;

  try {
    const updatedOffer = yield call(() => updateOfferStatus(offer, newStatus));
    yield put(notificationActions.showSuccessMessage(i18n.t('use-cases:new-assignment:root:saved-notification:title')));
    yield put(offerActions.updateOfferStatusSuccess(updatedOffer));

    if (newStatus.status === StateType.approved || newStatus.status === StateType.rejected) {
      yield put(push(`/assignment/all`));
    }
  } catch (e) {
    offer.state.name = StateType.unsavedChanges;
    yield put(notificationActions.showError(i18n.t('use-cases:new-assignment:root:saving-failed-notification:body')));
    yield put(offerActions.updateOfferStatusFailure());
  }
}

function* onUpdateOfferAttachments() {
  const offer: Offer = yield select((state: AppState) => state.offer.currentOffer.offer);
  (offer as any).dao.attachments = offer.attachments.map(attachment => attachment.toJson());

  try {
    const updatedOffer = yield call(() => updateOfferAttachments(offer));
    yield call(() => offer.loadAttachments());
    yield put(notificationActions.showSuccessMessage(i18n.t('use-cases:new-assignment:root:saved-notification:title')));
    yield put(offerActions.updateOfferAttachmentsSuccess(updatedOffer));
  } catch (error) {
    yield put(notificationActions.showError(i18n.t('use-cases:new-assignment:root:saving-failed-notification:body')));
    yield put(offerActions.updateOfferAttachmentsFailure());
  }
}

function* onSaveCurrentOffer(action: PayloadAction<StateType>) {
  const user: MUser = yield select((state: AppState) => state.auth.user);
  const offer: Offer = yield select((state: AppState) => state.offer.currentOffer.offer);
  const contacts: ContactObj[] = yield select((state: AppState) => state.offer.currentContacts);
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const isCreation = Boolean(offer.ID) === false;
  const newStatus = action.payload;

  prepareOfferForSaving(
    offer,
    contacts.map((el) => el.contactObject)
  );

  if (isOnline === false) {
    if (isCreation) {
      (offer as any).dao.id = uuid();
      (offer as any).dao.creator = user.userName;
    }

    yield put(offlineActions.addItemIntoQueue({ offer, newStatus, mutationType: isCreation ? 'create' : 'save' }));

    offer.state.name = StateType.unsavedChanges;

    if (isCreation) {
      yield put(push(`/assignment/${offer.measureType === OfferType.mis ? 'mis' : 'mwi'}/${offer.ID}`));
    }

    yield put(notificationActions.showSuccessMessage(i18n.t('assignment:status:offline')));
    yield put(offerActions.saveCurrentOfferSuccess(offer));
    return;
  }

  try {
    const updatedOffer = yield call(() => saveOffer(offer, newStatus, isCreation));

    yield put(offerActions.updateCurrentOffer(updatedOffer));

    if (isCreation) {
      yield put(push(`/assignment/${updatedOffer.measureType === OfferType.mis ? 'mis' : 'mwi'}/${offer.ID}`));
    }

    yield put(notificationActions.showSuccessMessage(i18n.t('use-cases:new-assignment:root:saved-notification:title')));
    yield put(offerActions.saveCurrentOfferSuccess(updatedOffer));
  } catch (error) {
    offer.state.name = StateType.unsavedChanges;
    yield put(offerActions.updateCurrentOffer(offer));

    yield put(notificationActions.showError(i18n.t('use-cases:new-assignment:root:saving-failed-notification:body')));
    yield put(offerActions.saveCurrentOfferFailure());
  }
}

function* onDeleteOffer(action: PayloadAction<Offer>) {
  const offer = action.payload;
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  if (isOnline === false) {
    yield put(offlineActions.addItemIntoQueue({ offer, mutationType: 'delete' }));
    yield put(offerActions.deleteOfferSuccess(offer.ID));
    return;
  }

  try {
    yield call(() => offer.delete());
    yield put(notificationActions.showSuccessMessage(i18n.t('my-assignment:actions:delete:success')));
    yield put(offerActions.deleteOfferSuccess(offer.ID));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(offerActions.deleteOfferFailure());
  }
}

export function* offerSaga() {
  yield all([
    takeLatest(offerActions.loadOffers, onLoadOffers),
    takeLeading(offerActions.loadMoreOffers, onLoadMoreOffers),
    takeLatest(offerActions.deleteOffer, onDeleteOffer),
    takeLatest(offerActions.loadCurrentOffer, onLoadCurrentOffer),
    takeLatest(offerActions.updateOfferStatus, onUpdateOfferStatus),
    takeLatest(offerActions.updateOfferAttachments, onUpdateOfferAttachments),
    takeLatest(offerActions.saveCurrentOffer, onSaveCurrentOffer),
    takeLatest(offerActions.loadOrCreateChangeOffer, onLoadOrCreateChangeOffer),
    takeLatest(offerActions.loadAndCopyCurrentOffer,onLoadAndCopyCurrentOffer),
  ]);
}

/** TRANSFORMS */
export const offerTransform = createTransform(
  (state: OfferState) => {
    return {
      ...state,
      offers: state.offers.map(offerToJson),
      currentOffer: state.currentOffer?.offer ? offerToJson(state.currentOffer?.offer) : null,
      currentContacts: [],
    };
  },
  (json) => {
    return {
      ...json,
      offers: json.offers && json.offers.map(offerFromJson),
      incompleteOffers: json.incompleteOffers || {},
      currentOffer: null,
      currentContacts: [],
      loading: 'idle',
      loadingOffer: 'idle',
    };
  },
  { whitelist: ['offer'] }
);
