import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import { notificationActions } from './notification';
import { AppState } from './index';
import ContactBook from '../sdk/com/apiomat/frontend/missio/ContactBook';
import Contact from '../sdk/com/apiomat/frontend/missio/Contact';
import { createTransform } from 'redux-persist';
import { copyContact, getGeneralContactType } from '../utils/contact.utils';
import { v4 as uuid } from 'uuid';
import { offlineActions } from './offline';
import MUser from '../sdk/com/apiomat/frontend/missio/MUser';

/** STATE */
export interface ContactBookState {
  contactBook: ContactBook | null;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: ContactBookState = {
  contactBook: null,
  loading: 'idle',
};

/** SLICE */
const contactBookSlice = createSlice({
  name: 'contactBook',
  initialState,
  reducers: {
    loadContactBook: (state, _action: PayloadAction<string>) => {
      state.loading = 'pending';
    },
    loadContactBookSuccess: (state, action: PayloadAction<ContactBook>) => {
      state.loading = 'succeeded';
      state.contactBook = action.payload;
    },
    loadContactBookFailure: (state) => {
      state.loading = 'failed';
    },
    deleteContact: (state, _action: PayloadAction<Contact>) => {
      state.loading = 'pending';
    },
    deleteContactSuccess: (state, action: PayloadAction<ContactBook>) => {
      state.loading = 'succeeded';
      state.contactBook = action.payload;
    },
    deleteContactFailure: (state) => {
      state.loading = 'failed';
    },
    updateContact: (state, _action: PayloadAction<Contact>) => {
      state.loading = 'pending';
    },
    updateContactSuccess: (state, action: PayloadAction<ContactBook>) => {
      state.loading = 'succeeded';
      state.contactBook = action.payload;
    },
    updateContactFailure: (state) => {
      state.loading = 'failed';
    },
    addContact: (state, _action: PayloadAction<Contact>) => {
      state.loading = 'pending';
    },
    addContactSuccess: (state, action: PayloadAction<ContactBook>) => {
      state.loading = 'succeeded';
      state.contactBook = action.payload;
    },
    addContactFailure: (state) => {
      state.loading = 'failed';
    },
  },
});

export const contactBookActions = contactBookSlice.actions;
export const contactBookReducer = contactBookSlice.reducer;

/** SAGAS */
function* onLoadContactBook() {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const contactBook: ContactBook = yield select((state: AppState) => state.contactBook.contactBook);
  const user: MUser = yield select((state: AppState) => state.auth.user);

  if (isOnline === false) {
    yield put(contactBookActions.loadContactBookSuccess(contactBook));
    return;
  }

  try {
    const contactBooks: ContactBook[] = yield call(() => ContactBook.getContactBooks(`ownerUserName == "${user.userName}" limit 1`));
    yield put(contactBookActions.loadContactBookSuccess(contactBooks.length === 1 ? contactBooks[0] : null));
  } catch (error) {
    if (error.statusCode === 840) {
      yield put(contactBookActions.loadContactBookSuccess(null));
    } else {
      yield put(notificationActions.showError(error));
      yield put(contactBookActions.loadContactBookFailure());
    }
  }
}

export function* onDeleteContact(action: PayloadAction<Contact>) {
  const contactBook: ContactBook = yield select((state: AppState) => state.contactBook.contactBook);
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);

  const newContactList: Contact[] = contactBook.contacts.filter((contact) => contact.ID !== action.payload.ID);
  (contactBook as any).hashmap.contacts = newContactList;
  (contactBook as any).dao.contacts = newContactList.map((contact) => contact.toJson());

  if (isOnline === false) {
    yield put(offlineActions.addContactBookIntoQueue({ contactBook, mutationType: 'delete' }));
    yield put(contactBookActions.deleteContactSuccess(contactBook));
    return;
  }

  try {
    yield call(() => contactBook.save());
    yield put(contactBookActions.deleteContactSuccess(contactBook));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(contactBookActions.deleteContactFailure());
  }
}

export function* onUpdateContact(action: PayloadAction<Contact>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  const contactBook = yield select((state: AppState) => state.contactBook.contactBook);

  const updatedContact = action.payload;
  updatedContact.generalContactType = getGeneralContactType(updatedContact.contactType);
  const filteredContactList: Contact[] = contactBook.contacts.filter((contact) => contact.ID !== updatedContact.ID);
  const newContactList = [...filteredContactList, action.payload];
  (contactBook as any).hashmap.contacts = newContactList;
  (contactBook as any).dao.contacts = newContactList.map((contact) => contact.toJson());

  if (isOnline === false) {
    yield put(offlineActions.addContactBookIntoQueue({ contactBook, mutationType: 'save' }));
    yield put(contactBookActions.addContactSuccess(contactBook));
    return;
  }

  try {
    yield call(() => contactBook.save());
    yield put(contactBookActions.updateContactSuccess(contactBook));
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(contactBookActions.updateContactFailure());
  }
}
export function* onAddContact(action: PayloadAction<Contact>) {
  const isOnline: boolean = yield select((state: AppState) => state.offline.isOnline);
  let contactBook: ContactBook = yield select((state: AppState) => state.contactBook.contactBook);
  const isCreation = Boolean(contactBook === null);

  if (isCreation) {
    contactBook = new ContactBook();
  }

  if (isOnline === false) {
    if (isCreation) {
      const contactList = [action.payload];
      (contactBook as any).dao.id = uuid();
      (contactBook as any).hashmap.contacts = contactList;
      (contactBook as any).dao.contacts = contactList.map((contact) => contact.toJson());
    }
    yield put(offlineActions.addContactBookIntoQueue({ contactBook, mutationType: isCreation ? 'create' : 'save' }));
    yield put(contactBookActions.addContactSuccess(contactBook));
    return;
  }

  try {
    let newContact = copyContact(action.payload);
    (newContact as any).dao.id = null;
    newContact.generalContactType = getGeneralContactType(newContact.contactType);
    const newContactList = [...contactBook.contacts, newContact];

    const index = newContactList.indexOf(newContact);

    (contactBook as any).hashmap.contacts = newContactList;
    (contactBook as any).dao.contacts = newContactList.map((contact) => contact.toJson());

    yield call(() => contactBook.save());
    yield put(contactBookActions.addContactSuccess(contactBook));
    (newContact as any).dao.id = contactBook.contacts[index].ID;
  } catch (error) {
    yield put(notificationActions.showError(error));
    yield put(contactBookActions.addContactFailure());
  }
}

export function* contactSaga() {
  yield all([
    takeLatest(contactBookActions.loadContactBook, onLoadContactBook),
    takeLatest(contactBookActions.deleteContact, onDeleteContact),
    takeLatest(contactBookActions.updateContact, onUpdateContact),
    takeLatest(contactBookActions.addContact, onAddContact),
  ]);
}

/** TRANSFORMS */
export const contactBookTransform = createTransform(
  (state: ContactBookState) => {
    return {
      ...state,
      contactBook: state.contactBook?.toJson(),
    };
  },
  (json) => {
    return {
      ...json,
      contactBook: null,
      loading: 'idle',
    };
  },
  { whitelist: ['contactBook'] }
);
