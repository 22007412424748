import AbstractClientDataModel from '../sdk/com/apiomat/frontend/AbstractClientDataModel';
import Offer from '../sdk/com/apiomat/frontend/missio/Offer';
import State from '../sdk/com/apiomat/frontend/missio/State';
import Measure from '../sdk/com/apiomat/frontend/missio/Measure';
import Report from '../sdk/com/apiomat/frontend/missio/Report';
import { updateMeasureClass } from './measure.utils';
import OfferEvaluation from '../sdk/com/apiomat/frontend/missio/OfferEvaluation';
import Audit from '../sdk/com/apiomat/frontend/missio/Audit';
import Survey from '../sdk/com/apiomat/frontend/missio/Survey';

export const fromJson = (json: any, clazz: any) => {
  const instance = new clazz();
  return json && instance.fromJson(json);
};

export const toJson = (instance: AbstractClientDataModel): any => {
  return instance.toJson();
};

export const offerToJson = (offer: Offer): any => {
  const json = offer.toJson();

  (json as any).hashmap = {
    measure: offer.measure?.toJson(),
    state: offer.state.toJson(),
  };

  return json;
};

export const offerFromJson = (json: any) => {
  const instance = new Offer();
  instance.fromJson(json);

  (instance as any).hashmap.measure = new Measure();
  instance.measure.fromJson(json.hashmap.measure);
  updateMeasureClass(instance, instance.measure.name);

  (instance as any).hashmap.state = new State();
  instance.state.fromJson(json.hashmap.state);

  return instance;
};

export const reportToJson = (report: Report): any => {
  const json = report.toJson();

  (json as any).hashmap = {
    measure: report?.measure?.toJson(),
    state: report?.state?.toJson(),
    offer: report?.offer?.toJson(),
  };

  return json;
};

export const reportFromJson = (json: any) => {
  const instance = new Report();
  instance.fromJson(json);

  (instance as any).hashmap.measure = new Measure();
  instance.measure.fromJson(json.hashmap.measure);
  updateMeasureClass(instance, instance.measure.name);

  (instance as any).hashmap.state = new State();
  instance.state.fromJson(json.hashmap.state);

  return instance;
};

export const evaluationToJson = (evaluation: OfferEvaluation): any => {
  const json = evaluation.toJson();

  (json as any).hashmap = {
    offer: evaluation?.offer && offerToJson(evaluation?.offer),
    state: evaluation?.state?.toJson(),
  };

  return json;
};

export const evaluationFromJson = (json: any) => {
  const instance = new OfferEvaluation();
  instance.fromJson(json);

  (instance as any).hashmap.state = new State();
  instance.state.fromJson(json.hashmap.state);

  return instance;
};

export const auditToJson = (audit: Audit): any => {
  const json = audit.toJson();

  (json as any).hashmap = {
    offer: audit.offer && offerToJson(audit.offer),
    state: audit.state?.toJson(),
  };

  return json;
};

export const auditFromJson = (json: any) => {
  const instance = new Audit();
  instance.fromJson(json);

  (instance as any).hashmap.state = new State();
  instance.state.fromJson(json.hashmap.state);

  return instance;
};

export const surveyToJson = (survey: Survey): any => {
  const json = survey.toJson();

  (json as any).hashmap = {
    offer: survey.offer?.toJson(),
    state: survey.state?.toJson(),
  };

  return json;
};

export const surveyFromJson = (json: any) => {
  const instance = new Survey();
  instance.fromJson(json);

  (instance as any).hashmap.state = new State();
  instance.state.fromJson(json.hashmap.state);

  return instance;
};

export const fromStringToDate = (time: string | number | undefined): Date => (Boolean(time) ? new Date(Number(time)) : undefined);

export const fromDateToNumber = (date: Date): number => date.getTime();
