import React, { memo, useEffect, useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Monitoring from '../../../sdk/com/apiomat/frontend/missio/Monitoring';
import Offer from '../../../sdk/com/apiomat/frontend/missio/Offer';
import Report from '../../../sdk/com/apiomat/frontend/missio/Report';
import { useFormik } from 'formik';
import { object, string, number } from 'yup';
import { Action, useOfferFormContext } from '../context/OfferFormContext';
import FormTextField from '../InputFields/FormTextField';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

const useStyles = makeStyles(() => ({
  contentContainer: {
    paddingBottom: 30,
    paddingTop: 30,
  },
}));

export interface AchievementFormProps {
  readOnlyMode: boolean;
  monitoring?: Monitoring;
  data?: Offer | Report;
  isReport?: boolean;
}

const AchievementForm = memo((props: AchievementFormProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;

  const { t } = useTranslation();

  const classes = useStyles();

  const validationSchema = useMemo(
    () =>
      object({
        targetDescription: string(),
        targetGroupDescription: string().required(t('mandatory_field')),
        targetGroupParticipation: string().required(t('mandatory_field')),
        numberOfMen: number()
          .min(0, t('input:error:negative-number-of-people'))
          .required(t('mandatory_field')),
        numberOfMenUnderEighteen: number().when('numberOfMen', numberOfMen =>
          number()
            .min(0, t('input:error:negative-number-of-people'))
            .max(numberOfMen, t('input:error:inconsistent-number-of-people-underage-in-the-group'))
            .required(t('mandatory_field'))
        ),
        numberOfWomen: number()
          .min(0, t('input:error:negative-number-of-people'))
          .required(t('mandatory_field')),
        numberOfWomenUnderEighteen: number().when('numberOfWomen', numberOfWomen =>
          number()
            .min(0, t('input:error:negative-number-of-people'))
            .max(numberOfWomen, t('input:error:inconsistent-number-of-people-underage-in-the-group'))
            .required(t('mandatory_field'))
        ),
        indirectTargetGroupCount: isReport ? number().required(t('mandatory_field')) : number(),
        stakeholder: string(),
      }),
    [t]
  );

  // necessary for deep content equality check
  const dataComparisonObject = useMemo(() => {
    return {
      targetDescription: data?.targetGroup?.targetDescription,
      targetGroupDescription: data?.targetGroup?.targetGroupDescription,
      targetGroupParticipation: data?.targetGroup?.targetGroupParticipation,
      numberOfMen: data?.targetGroup?.numberOfMen,
      numberOfMenUnderEighteen: data?.targetGroup?.numberOfMenUnderEighteen,
      numberOfWomen: data?.targetGroup?.numberOfWomen,
      numberOfWomenUnderEighteen: data?.targetGroup?.numberOfWomenUnderEighteen,
      indirectTargetGroupCount: data?.targetGroup?.indirectTargetGroupCount,
      stakeholder: data?.targetGroup?.stakeholder,
    };
  }, [data]);

  const formik = useFormik({
    initialValues: {
      ...dataComparisonObject,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      const actionObject: Action = {
        subFormId: 'target',
        errors: formik.errors,
        values: {
          ...formik.values,
          totalNumberOfMenAndWomen: (formik.values.numberOfMen ?? 0) + (formik.values.numberOfWomen ?? 0),
          totalNumberOfMenAndWomenUnderEighteen:
            (formik.values.numberOfMenUnderEighteen ?? 0) + (formik.values.numberOfWomenUnderEighteen ?? 0),
        },
      };

      if (!('target' in state) || ('target' in state && !isEqual(state['target'].values, actionObject.values))) {
        dispatch(actionObject);
      }
    }
  }, [formik.errors, formik.values, state, dispatch, readOnlyMode]);

  return (
    <div className={classes.contentContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormTextField
            id="targetDescription"
            name="targetDescription"
            disabled={readOnlyMode}
            multiline
            rows={3}
            labelKey="input:label:achievement-description"
            descriptionKey="info:achievement-description"
            value={formik.values.targetDescription}
            onChange={formik.handleChange}
            errorMessage={formik.errors.targetDescription}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="targetGroupDescription"
            name="targetGroupDescription"
            disabled={readOnlyMode}
            multiline
            rows={3}
            labelKey="input:label:group-description"
            descriptionKey="info:achievement-group-description"
            value={formik.values.targetGroupDescription}
            onChange={formik.handleChange}
            errorMessage={formik.errors.targetGroupDescription}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="targetGroupParticipation"
            name="targetGroupParticipation"
            disabled={readOnlyMode}
            rows={3}
            multiline
            labelKey="input:label:group-participation"
            descriptionKey="info:achievement-group-participation"
            value={formik.values.targetGroupParticipation}
            onChange={formik.handleChange}
            errorMessage={formik.errors.targetGroupParticipation}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="numberOfMen"
            name="numberOfMen"
            type="number"
            disabled={readOnlyMode}
            inputProps={{ min: 0 }}
            labelKey="input:label:number-of-men-in-the-group"
            value={formik.values.numberOfMen}
            onChange={formik.handleChange}
            errorMessage={formik.errors.numberOfMen}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="numberOfMenUnderEighteen"
            name="numberOfMenUnderEighteen"
            type="number"
            disabled={readOnlyMode}
            labelKey="input:label:number-of-men-underage-in-the-group"
            value={formik.values.numberOfMenUnderEighteen}
            onChange={formik.handleChange}
            errorMessage={formik.errors.numberOfMenUnderEighteen}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="numberOfWomen"
            name="numberOfWomen"
            type="number"
            disabled={readOnlyMode}
            labelKey="input:label:number-of-women-in-the-group"
            value={formik.values.numberOfWomen}
            onChange={formik.handleChange}
            errorMessage={formik.errors.numberOfWomen}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="numberOfWomenUnderEighteen"
            name="numberOfWomenUnderEighteen"
            type="number"
            disabled={readOnlyMode}
            labelKey="input:label:number-of-women-underage-in-the-group"
            value={formik.values.numberOfWomenUnderEighteen}
            onChange={formik.handleChange}
            errorMessage={formik.errors.numberOfWomenUnderEighteen}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            type="number"
            id="sumNumberOfMen"
            name="sumNumberOfMen"
            disabled
            labelKey="input:label:total-number-of-adults-in-the-group"
            value={(formik.values.numberOfMen ?? 0) + (formik.values.numberOfWomen ?? 0)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            type="number"
            id="sumNumberOfMenUnderEighteen"
            name="sumNumberOfMenUnderEighteen"
            disabled
            value={(formik.values.numberOfMenUnderEighteen ?? 0) + (formik.values.numberOfWomenUnderEighteen ?? 0)}
            labelKey="input:label:total-number-of-underage-in-the-group"
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="indirectTargetGroupCount"
            name="indirectTargetGroupCount"
            type="number"
            disabled={readOnlyMode}
            value={formik.values.indirectTargetGroupCount}
            labelKey="input:label:total-number-of-participants-in-the-group"
            onChange={formik.handleChange}
            errorMessage={formik.errors.indirectTargetGroupCount}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="stakeholder"
            name="stakeholder"
            labelKey="input:label:stakeholder"
            descriptionKey="info:achievement-stakeholder"
            disabled={readOnlyMode}
            value={formik.values.stakeholder}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
    </div>
  );
});

export default AchievementForm;
