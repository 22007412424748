import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Offer from '../../../../sdk/com/apiomat/frontend/missio/Offer';
import AcquisitionOfEquipment from '../../../../sdk/com/apiomat/frontend/missio/AcquisitionOfEquipment';
import Report from '../../../../sdk/com/apiomat/frontend/missio/Report';
import { useFormik } from 'formik';
import { object, date, string } from 'yup';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';
import { useOfferFormContext } from '../../context/OfferFormContext';

export interface EquipmentPurchaseProps {
  readOnlyMode: boolean;
  data: Offer | Report;
  isReport?: boolean;
}

const EquipmentPurchase = (props: EquipmentPurchaseProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const { t } = useTranslation();

  const {
    start,
    end,
    measureDescription,
    equipmentDescription,
    existingEquipment,
    equipmentUpkeepCosts,
  } = data.measure as AcquisitionOfEquipment;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),
        equipmentDescription: string().required(t('mandatory_field')),
        existingEquipment: isReport ? string() : string().required(t('mandatory_field')),
        equipmentUpkeepCosts: string().required(t('mandatory_field')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      equipmentDescription,
      existingEquipment,
      equipmentUpkeepCosts,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  return (
    <div>
      <Grid container spacing={3}>
        <>
          <Grid item xs={6}>
            <FormDatePicker
              id="start"
              name="start"
              label={t('input:label:measure:planned-project-start')}
              value={formik.values.start}
              onChange={value => formik.setFieldValue('start', value)}
              disabled={readOnlyMode}
              errorMessage={formik.errors.start}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDatePicker
              id="end"
              name="end"
              label={t('input:label:measure:planned-project-end')}
              value={formik.values.end}
              minDate={formik.values.start}
              onChange={value => formik.setFieldValue('end', value)}
              disabled={readOnlyMode}
              errorMessage={formik.errors.end}
            />
          </Grid>
        </>

        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="equipmentDescription"
            name="equipmentDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:equipment-description"
            descriptionKey="info:measure:equipment-purchase:equipment-description"
            value={formik.values.equipmentDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.equipmentDescription}
          />
        </Grid>
        {!isReport && (
          <Grid item xs={12}>
            <FormTextField
              id="existingEquipment"
              name="existingEquipment"
              disabled={readOnlyMode}
              labelKey="input:label:measure:equipment-availability"
              descriptionKey="info:measure:equipment-purchase:equipment-availability"
              value={formik.values.existingEquipment}
              multiline
              rows={3}
              onChange={formik.handleChange}
              errorMessage={formik.errors.existingEquipment}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="name"
            name="equipmentUpkeepCosts"
            disabled={readOnlyMode}
            labelKey="input:label:measure:equipment-cost"
            descriptionKey="info:measure:equipment-purchase:equipment-cost"
            value={formik.values.equipmentUpkeepCosts}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.equipmentUpkeepCosts}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EquipmentPurchase;
