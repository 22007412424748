import React, { useEffect } from 'react';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppState } from '../../store';
import { makeStyles } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { reportActions } from '../../store/report';
import Report from '../../sdk/com/apiomat/frontend/missio/Report';
import { Link, useParams } from 'react-router-dom';
import { ApplicationStatus } from '../../components/ApplicationStatus/ApplicationStatus';
import { ActualDeviationStatus } from '../../components/ActualDeviationStatus.tsx/ActualDeviationStatus';
import { State } from '../../sdk/com/apiomat/frontend/missio';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import ActionButton from '@mui/material/Button';

interface TableSharedReports {
  application: string;
  deadline: Date;
  status: string;
  completed: State;
  actions: Report;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '& > div div table thead tr th.MuiTableCell-head': {
      '& > span > button': {
        padding: 0,
        textTransform: 'none',
        textAlign: 'left'
      }
    }
  },
  applicationButton: {
    wordBreak: 'break-all'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    '& > button': {
      marginRight: 5
    }
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { offerId } = useParams<{ offerId: string | undefined }>();

  const user = useSelector((state: AppState) => state.auth.user);

  useEffect(() => {
    const query = `sharedWith == "${ user?.userName }"`;

    dispatch(reportActions.updateIsSharedReport(true));
    dispatch(reportActions.loadSharedReports(query));
  }, [ dispatch, offerId, user ]);

  const { sharedReports, loadingReports, loadingCurrentReport } = useSelector((state: AppState) => state.report);

  const columns: Array<MUIDataTableColumnDef> = [
    {
      name: 'application',
      label: t('my-tasks:header:application'),
      options: {
        sort: false
      }
    },
    {
      name: 'deadline',
      label: t('my-tasks:header:deadline'),
      options: {
        customBodyRender: (value) => value.toLocaleDateString('de-DE'),
        sort: true
      }
    },
    {
      name: 'status',
      label: t('my-tasks:header:status'),
      options: {
        sort: false
      }
    },
    {
      name: 'completed',
      label: t('my-tasks:header:completed'),
      options: {
        customBodyRender: (value) => <ApplicationStatus state={ value }></ApplicationStatus>,
        sort: false
      }
    },
    {
      name: 'targetCompleted',
      label: t('my-tasks:header:target-completed'),
      options: {
        customBodyRender: (value) => value.isReport && <ActualDeviationStatus hasDeviations={ value.value }/>,
        sort: false
      }
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        customBodyRender: (value) => {
          return (
            <div className={ classes.buttonsContainer }>
              <ActionButton variant="outlined"
                            startIcon={ <VisibilityIcon/> }
                            color="primary"
                            component={ Link }
                            to={ `/tasks/report/new/${ value.ID }` }
              >
                { t('my-tasks:actions:view') }
              </ActionButton>
            </div>
          );
        },
        sort: false
      }
    },
  ];

  const datasource: Array<TableSharedReports> = [ ...sharedReports ].map(data => {
    return {
      application: data?.offerName,
      deadline: data?.dueDate,
      status: t(`reports:status:${ data.state?.name }`),
      completed: data?.state,
      targetCompleted: { value: data?.hasCostPlanDeviations === 1, isReport: data instanceof Report },
      actions: data
    };
  });

  return loadingReports === 'pending' || loadingCurrentReport === 'pending' ? (
    <LoadingIndicator/>
  ) : (
    <div className={ classes.container }>
      <MUIDataTable
        title={ '' }
        data={ datasource }
        columns={ columns }
        options={ {
          selectableRows: 'none',
          print: false,
          download: false,
          filter: false,
          sort: true,
          sortOrder: {
            name: 'deadline',
            direction: 'desc'
          },
          viewColumns: false,
          search: false
        } }
      />
    </div>
  );
};
