import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { offerActions } from '../../../store/offers';
import Report from '../../../sdk/com/apiomat/frontend/missio/Report';
import Offer from '../../../sdk/com/apiomat/frontend/missio/Offer';
import Monitoring from '../../../sdk/com/apiomat/frontend/missio/Monitoring';
import { reportActions } from '../../../store/report';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Action, useOfferFormContext } from '../context/OfferFormContext';
import FormTextField from '../InputFields/FormTextField';

const useStyles = makeStyles(() => ({
  contentContainer: {
    paddingTop: 30,
    paddingBottom: 30,
  },
}));

export interface MonitoringFormProps {
  readOnlyMode: boolean;
  monitoring?: Monitoring;
  data?: Offer | Report;
  isReport?: boolean;
}

const MonitoringForm = (props: MonitoringFormProps) => {
  const { readOnlyMode, isReport, data } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const monitoring = data?.monitoring;

  const { state, dispatch: dispatchOfferContext } = useOfferFormContext();
  const [ targetCheck, setTargetCheck ] = useState<string>(monitoring.targetCheck);
  const [ sustained, setSustained ] = useState<string>(monitoring.sustained);

  const updateData = (data: any) => {
    isReport ? dispatch(reportActions.updateCurrentReport(data)) : dispatch(offerActions.updateCurrentOffer(data));
  };

  useEffect(() => {
    if (!readOnlyMode) {
      const actionObject: Action = {
        subFormId: 'monitoring',
        errors: {},
        values: {},
      };
      if (isEmpty(targetCheck)) {
        actionObject.errors['targetCheck'] = 'mandatory field';
      }
      if (isEmpty(sustained)) {
        actionObject.errors['sustained'] = 'mandatory field';
      }

      const formErrorCount = (isEmpty(targetCheck) ? 1 : 0) + (isEmpty(sustained) ? 1 : 0);
      if (!('monitoring' in state)) {
        dispatchOfferContext(actionObject);
      } else if (state['monitoring'].errorCount !== formErrorCount) {
        dispatchOfferContext(actionObject);
      }
    }
  }, [ dispatchOfferContext, readOnlyMode, state, sustained, targetCheck ]);

  return (
    <Grid container spacing={ 3 } className={ classes.contentContainer }>
      <Grid item xs={ 12 }>
        <FormTextField
          id="targetCheck"
          name="targetCheck"
          disabled={ readOnlyMode }
          labelKey="input:label:examination-of-goals-achievement"
          descriptionKey="info:monitoring-examination-of-goals-achievement"
          value={ targetCheck }
          multiline
          rows={ 3 }
          onChange={ event => {
            setTargetCheck(event.target.value);
            monitoring.targetCheck = event.target.value;
            updateData(data);
          } }
          errorMessage={ isEmpty(targetCheck) ? t('mandatory_field') : undefined }
        />
      </Grid>
      <Grid item xs={ 12 }>
        <FormTextField
          id="sustained"
          name="sustained"
          disabled={ readOnlyMode }
          labelKey="input:label:sustainability"
          descriptionKey="info:monitoring-sustainability"
          value={ monitoring?.sustained }
          multiline
          rows={ 3 }
          onChange={ event => {
            setSustained(event.target.value);
            monitoring.sustained = event.target.value;
            updateData(data);
          } }
          errorMessage={ isEmpty(sustained) ? t('mandatory_field') : undefined }
        />
      </Grid>
    </Grid>
  );
};

export default MonitoringForm;
