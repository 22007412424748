import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppState } from '../../store';
import { Button, makeStyles } from '@material-ui/core';
import ActionButton from '@mui/material/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { reportActions } from '../../store/report';
import Report from '../../sdk/com/apiomat/frontend/missio/Report';
import { Link, useParams } from 'react-router-dom';
import { OfferType } from '../../enums/OfferType';
import { StateType } from '../../enums/StateType';
import EditIcon from '@material-ui/icons/Edit';
import { ActualDeviationStatus } from '../../components/ActualDeviationStatus.tsx/ActualDeviationStatus';
import { ApplicationStatus } from '../../components/ApplicationStatus/ApplicationStatus';
import { evaluationActions } from '../../store/evaluation';
import { auditActions } from '../../store/audit';
import Audit from '../../sdk/com/apiomat/frontend/missio/Audit';
import OfferEvaluation from '../../sdk/com/apiomat/frontend/missio/OfferEvaluation';
import { shareReportActions } from '../../store/shareReport';
import ShareIcon from '@material-ui/icons/Share';
import ShareReportDialog from '../../components/Dialogs/ShareReportDialog';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { State } from '../../sdk/com/apiomat/frontend/missio';

interface TableMyTasks {
  type: string;
  application: Report | Audit | OfferEvaluation;
  deadline: Date;
  status: string;
  completed: State;
  targetCompleted: { value: boolean, isReport: boolean };
  actions: Report | Audit | OfferEvaluation;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '& > div div table thead tr th.MuiTableCell-head': {
      '& > span > button': {
        padding: 0,
        textTransform: 'none',
        textAlign: 'left'
      }
    }
  },
  applicationButton: {
    wordBreak: 'break-all'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    '& > button': {
      marginRight: 5
    }
  },
}));

interface MyTaskProps {
  querySuffix: String;
}

const MyTasks = (props: MyTaskProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { offerId, isSurvey } = useParams<{ offerId: string | undefined; isSurvey: string | undefined }>();
  const { querySuffix } = props;

  const user = useSelector((state: AppState) => state.auth.user);
  const [ isShareReportDialogOpen, setIsShareReportDialogOpen ] = useState(false);

  useEffect(() => {
    let query = '';
    if (offerId && isSurvey) {
      query = `offer.id == id("${ offerId }")`;
    } else if (offerId) {
      query = `offer.id == id("${ offerId }") and ownerUserName == "${ user?.userName }"`;
    } else {
      query = `ownerUserName == "${ user?.userName }" ${ querySuffix }`;
    }

    dispatch(reportActions.updateIsSharedReport(false));
    dispatch(reportActions.loadReports(query));
    dispatch(evaluationActions.loadEvaluations(query));
    dispatch(auditActions.loadAudits(query));
  }, [ dispatch, isSurvey, offerId, querySuffix, user ]);

  const { reports, loadingReports, loadingCurrentReport } = useSelector((state: AppState) => state.report);
  const { audits, loadingAudits, loadingCurrentAudit } = useSelector((state: AppState) => state.audit);
  const { evaluations, loadingEvaluations, loadingCurrentEvaluation } = useSelector((state: AppState) => state.evaluation);

  const type = (element: any): string => {
    if (element instanceof Report) {
      const phase = element?.costPlan?.phase !== undefined ? ' - ' + t('label:cost-plan-table-phase') + ` ${ element?.costPlan?.phase }` : '';
      return t('reports:header:report-name') + phase;
    }
    if (element instanceof Audit) {
      return t('tab:title:audit');
    }
    if (element instanceof OfferEvaluation) {
      return t('tab:title:evaluation');
    }
  };

  const datasource: Array<TableMyTasks> = [ ...reports, ...evaluations, ...audits ].map(data => {
    return {
      type: type(data),
      application: data,
      deadline: data?.dueDate,
      status: t(`reports:status:${ data.state?.name }`),
      completed: data?.state,
      targetCompleted: { value: data?.hasCostPlanDeviations === 1, isReport: data instanceof Report },
      actions: data
    };
  });

  const linkUrl = (element: any): string => {
    if (element instanceof Report) {
      return `/tasks/report/new/${ element.ID }`;
    }
    if (element instanceof Audit) {
      return `/tasks/audit/new/${ element.ID }`;
    }
    if (element instanceof OfferEvaluation) {
      return `/tasks/evaluation/new/${ element.ID }`;
    }
  };

  const onCancelShareReportClick = () => {
    setIsShareReportDialogOpen(false);
  };

  const openShareReportDialog = (report: Report) => {
    dispatch(shareReportActions.updateCurrentSharedReport(report));
    setIsShareReportDialogOpen(true);
  };

  const columns: Array<MUIDataTableColumnDef> = [
    {
      name: 'type',
      label: t('my-tasks:header:type'),
      options: {
        sort: false
      }
    },
    {
      name: 'application',
      label: t('my-tasks:header:application'),
      options: {
        customBodyRender: (value) => (
          <Button
            href="#text-buttons"
            color="primary"
            component={ Link }
            className={ classes.applicationButton }
            to={ `/assignment/${ value.offer?.measureType === OfferType.mis ? 'mis' : 'mwi' }/${ value.offer?.ID }` }
          >
            { value.offer ? value.offer.name : ' ' }
          </Button>
        ),
        sort: false
      }
    },
    {
      name: 'deadline',
      label: t('my-tasks:header:deadline'),
      options: {
        customBodyRender: (value) => value.toLocaleDateString('de-DE'),
        sort: true
      }
    },
    {
      name: 'status',
      label: t('my-tasks:header:status'),
      options: {
        sort: false
      }
    },
    {
      name: 'completed',
      label: t('my-tasks:header:completed'),
      options: {
        customBodyRender: (value) => <ApplicationStatus state={ value }></ApplicationStatus>,
        sort: false
      }
    },
    {
      name: 'targetCompleted',
      label: t('my-tasks:header:target-completed'),
      options: {
        customBodyRender: (value) => value.isReport && <ActualDeviationStatus hasDeviations={ value.value }/>,
        sort: false
      }
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        customBodyRender: (value) => {
          return (
            <div className={ classes.buttonsContainer }>
              { value instanceof Report && !isSurvey && (
                <ActionButton variant="outlined"
                              startIcon={ <ShareIcon/> }
                              color="primary"
                              onClick={ () => openShareReportDialog(value) }
                >
                  { t('my-tasks:actions:share') }
                </ActionButton>
              ) }
              { value.state?.name === StateType.completed || value.state?.name === StateType.approved || value.state?.name === StateType.easydor ? (
                <ActionButton variant="outlined"
                              startIcon={ <VisibilityIcon/> }
                              color="primary"
                              component={ Link } to={ linkUrl(value) }
                >
                  { t('my-tasks:actions:view') }
                </ActionButton>
              ) : (
                <ActionButton variant="outlined"
                              startIcon={ <EditIcon/> }
                              color="primary"
                              component={ Link } to={ linkUrl(value) }
                >
                  { t('my-tasks:actions:edit') }
                </ActionButton>
              ) }
            </div>
          );
        },
        sort: false
      }
    },
  ];

  return loadingReports === 'pending' ||
    loadingCurrentReport === 'pending' ||
    loadingAudits === 'pending' ||
    loadingCurrentAudit === 'pending' ||
    loadingEvaluations === 'pending' ||
    loadingCurrentEvaluation === 'pending' ? (
    <LoadingIndicator/>
  ) : (
    <>
      <div className={ classes.container }>
        <MUIDataTable
          title={ '' }
          data={ datasource }
          columns={ columns }
          options={ {
            selectableRows: 'none',
            print: false,
            download: false,
            filter: false,
            sort: true,
            sortOrder: {
              name: 'deadline',
              direction: 'desc'
            },
            viewColumns: false,
            search: false
          } }
        />
      </div>
      <ShareReportDialog onCancelButtonClick={ onCancelShareReportClick } isOpen={ isShareReportDialogOpen }/>
    </>
  );
};

export default MyTasks;
