import React, { useEffect } from 'react';
import { offerActions } from '../../store/offers';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../store';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { StateType } from '../../enums/StateType';
import { OfferType } from '../../enums/OfferType';
import FullScreenLoadingIndicator from '../../components/Loading/FullScreenLoadingIndicator';
import { updateMeasureClass } from '../../utils/measure.utils';
import Offer from '../../sdk/com/apiomat/frontend/missio/Offer';
import NewAssignment from './NewAssignment';
import { OfferFormProvider } from '../../components/Forms/context/OfferFormContext';
import { OfferModes } from '../../enums/OfferModes';

interface AssignmentParams {
  id: string | undefined;
  mode: OfferModes | undefined;
}

export default () => {
  const dispatch = useDispatch();
  const { id, mode } = useParams<AssignmentParams>();

  const { currentOffer, loading, loadingOffer } = useSelector((state: AppState) => state.offer);

  useEffect(() => {
    if (id && mode && mode === OfferModes.proposal) {
      dispatch(offerActions.loadOrCreateChangeOffer(id));
    } else if (id && mode && mode === OfferModes.copy) {
      dispatch(offerActions.loadAndCopyCurrentOffer(id));
    } else if (id) {
      dispatch(offerActions.loadCurrentOffer(id));
    } else {
      dispatch(offerActions.createNewCurrentOffer(OfferType.mis));
    }
  }, [mode, dispatch, id]);

  const { referents, loadingReferents } = useSelector((state: AppState) => state.referent);

  const offer = currentOffer && currentOffer.offer;

  const readOnly =
    offer?.state?.name !== StateType.unsavedChanges &&
    offer?.state?.name !== StateType.cloud &&
    offer?.state?.name !== StateType.rejected &&
    offer?.state?.name !== StateType.proposalUserCloud;

  const isProposalUserCloud: boolean = Boolean(offer?.state?.name === StateType.proposalUserCloud);

  const updateOffer = (data: Offer) => {
    dispatch(offerActions.updateCurrentOffer(data));
  };

  const onTitleChange = (title: string) => {
    offer.name = title;
    dispatch(offerActions.updateCurrentOffer(offer));
  };

  const onMeasureChange = (name: string) => {
    offer.measure.name = name;
    updateMeasureClass(offer, name);
    dispatch(offerActions.updateCurrentOffer(offer));
  };

  if (loadingOffer === 'pending') {
    return <FullScreenLoadingIndicator />;
  }

  if (loading === 'pending' || loadingReferents === 'pending') {
    return <LoadingIndicator />;
  }

  if (!offer) {
    return null;
  }

  return (
    <OfferFormProvider key={Boolean(id) ? `assignment-mis-${id}` : `new-assignment-mis`}
                       data={offer}
                       onChange={updateOffer}>
      <NewAssignment
        data={offer}
        measureName={offer.measure?.name}
        measureType={offer.measureType}
        onMeasureChange={onMeasureChange}
        onTitleChange={onTitleChange}
        readOnly={readOnly}
        isProposal={Boolean(mode && mode === OfferModes.proposal) || isProposalUserCloud}
        referents={referents}
        title={offer.name}
      />
    </OfferFormProvider>
  );
};
