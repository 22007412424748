import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useMwiMeasuresStyles } from './Styles';
import { useTranslation } from 'react-i18next';
import RadioButtons from '../../../Buttons/RadioButtons';
import DialogButton from '../../../Dialogs/DialogButton';
import Offer from '../../../../sdk/com/apiomat/frontend/missio/Offer';
import MwiConference from '../../../../sdk/com/apiomat/frontend/missio/MwiConference';
import { countries } from '../../../../value-maps/Countries';
import DropDown from '../../../Selection/DropDown';
import { alphabeticalSort } from '../../../../utils/sort.util';
import { DropDownItem } from '../../../Selection/DropDownItem';
import { date, string, number, object } from 'yup';
import { useFormik } from 'formik';
import FormDatePicker from '../../../DatePicker/FormDatePicker';
import FormTextField from '../../InputFields/FormTextField';
import { useOfferFormContext } from '../../context/OfferFormContext';

export interface ConferenceProps {
  readOnlyMode: boolean;
  data: Offer;
  isReport?: boolean;
}

const Conference = (props: ConferenceProps) => {
  const { state, dispatch } = useOfferFormContext();
  const { readOnlyMode, data, isReport } = props;
  const classes = useMwiMeasuresStyles();
  const { t } = useTranslation();

  const {
    start,
    end,
    measureDescription,
    organiserName,
    organiserLocation,
    organiserCountry,
    university,
    location,
    country,
    title,
    numberOfParticipants,
    subject,
    topicMeaning,
    isScholarshipApplicationElsewhere,
    hasReadAdvertisingStatement,
    hasReadConditions,
  } = data.measure as MwiConference;

  const validationSchema = useMemo(
    () =>
      object({
        start: date().required(t('mandatory_field')),
        end: date().required(t('mandatory_field')),
        measureDescription: string().required(t('mandatory_field')),
        organiserName: isReport ? string() : string().required(t('mandatory_field')),
        organiserLocation: isReport ? string() : string().required(t('mandatory_field')),
        organiserCountry: isReport ? string() : string().required(t('mandatory_field')),
        university: isReport ? string() : string().required(t('mandatory_field')),
        location: isReport ? string() : string().required(t('mandatory_field')),
        country: isReport ? string() : string().required(t('mandatory_field')),
        title: isReport ? string() : string().required(t('mandatory_field')),
        numberOfParticipants: number().required(t('mandatory_field')),
        subject: isReport ? string() : string().required(t('mandatory_field')),
        topicMeaning: string().required(t('mandatory_field')),
        isScholarshipApplicationElsewhere: isReport ? string() : string().required(t('mandatory_field')),
        hasReadAdvertisingStatement: isReport ? number() : number().equals([1], t('mandatory_field')),
        hasReadConditions: isReport ? number() : number().equals([1], t('mandatory_field')),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      start,
      end,
      measureDescription,
      organiserName,
      organiserLocation,
      organiserCountry,
      university,
      location,
      country,
      title,
      numberOfParticipants,
      subject,
      topicMeaning,
      isScholarshipApplicationElsewhere,
      hasReadAdvertisingStatement,
      hasReadConditions,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!readOnlyMode) {
      if (!formik.isValidating) {
        dispatch({ subFormId: 'measure', errors: formik.errors, values: formik.values });
      }
    }
  }, [formik.errors, formik.values, dispatch, formik.isValidating, readOnlyMode, state]);

  const countryItems: DropDownItem[] = useMemo(() => countries.map(el => ({ key: el.key, value: t(el.key) })).sort(alphabeticalSort), [t]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormDatePicker
            id="start"
            name="start"
            label={t('input:label:measure:planned-project-start')}
            value={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('start', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.start}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDatePicker
            id="end"
            name="end"
            label={t('input:label:measure:planned-project-end')}
            value={formik.values.end}
            minDate={formik.values.start}
            onChange={(date: Date) => formik.setFieldValue('end', date)}
            disabled={readOnlyMode}
            errorMessage={formik.errors.end}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            id="measureDescription"
            name="measureDescription"
            disabled={readOnlyMode}
            labelKey="input:label:measure:measure-description"
            value={formik.values.measureDescription}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.measureDescription}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="organiserName"
                name="organiserName"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:conference:organizers-name"
                value={formik.values.organiserName}
                onChange={formik.handleChange}
                errorMessage={formik.errors.organiserName}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="organiserLocation"
                name="organiserLocation"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:conference:organizers-address"
                value={formik.values.organiserLocation}
                onChange={formik.handleChange}
                errorMessage={formik.errors.organiserLocation}
              />
            </Grid>
            <Grid item xs={12}>
              <DropDown
                labelKey="input:label:measure:mwi:conference:organizers-country"
                disabled={readOnlyMode}
                selectedItemKey={formik.values.organiserCountry}
                items={countryItems}
                onSelectedItemChange={(key: string) => formik.setFieldValue('organiserCountry', key)}
                errorMessage={formik.errors.organiserCountry}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="university"
                name="university"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:conference:university"
                descriptionKey="info:measure:mwi:conference:university"
                value={formik.values.university}
                onChange={formik.handleChange}
                errorMessage={formik.errors.university}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="location"
                name="location"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:conference:events-address"
                descriptionKey="info:measure:mwi:conference:events-address"
                value={formik.values.location}
                onChange={formik.handleChange}
                errorMessage={formik.errors.location}
              />
            </Grid>
            <Grid item xs={12}>
              <DropDown
                labelKey="input:label:measure:mwi:conference:events-country"
                disabled={readOnlyMode}
                selectedItemKey={formik.values.country}
                items={countryItems}
                onSelectedItemChange={(key: string) => formik.setFieldValue('country', key)}
                errorMessage={formik.errors.country}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                id="title"
                name="title"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:conference:conference-title"
                multiline
                rows={3}
                value={formik.values.title}
                onChange={formik.handleChange}
                errorMessage={formik.errors.title}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="numberOfParticipants"
            name="numberOfParticipants"
            type="number"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:conference:number-of-participants"
            value={formik.values.numberOfParticipants}
            onChange={formik.handleChange}
            errorMessage={formik.errors.numberOfParticipants}
          />
        </Grid>
        {!isReport && (
          <>
            <Grid item xs={12}>
              <FormTextField
                id="subject"
                name="subject"
                disabled={readOnlyMode}
                labelKey="input:label:measure:mwi:conference:subject"
                descriptionKey="info:measure:mwi:conference:subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                errorMessage={formik.errors.subject}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormTextField
            id="topicMeaning"
            name="topicMeaning"
            disabled={readOnlyMode}
            labelKey="input:label:measure:mwi:conference:meaning-of-the-topic"
            descriptionKey="info:measure:mwi:conference:meaning-of-the-topic"
            value={formik.values.topicMeaning}
            multiline
            rows={3}
            onChange={formik.handleChange}
            errorMessage={formik.errors.topicMeaning}
          />
        </Grid>
        {!isReport && (
          <>
            <div className={classes.questionContainer}>
              <div className={classes.questionScholarship}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:measure:mwi:library:scholarship-application')}
                    value={formik.values.isScholarshipApplicationElsewhere}
                    disabled={readOnlyMode}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('isScholarshipApplicationElsewhere', value)}
                    errorMessage={formik.errors.isScholarshipApplicationElsewhere}
                  />
                </Grid>
              </div>
              <div className={classes.questionOne}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:mwi:advertisement')}
                    disabled={readOnlyMode}
                    value={formik.values.hasReadAdvertisingStatement}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadAdvertisingStatement', value)}
                    errorMessage={formik.errors.hasReadAdvertisingStatement}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:conference:advertisement'}
                  title={'button:label:mwi:advertisement'}
                />
              </div>
              <div className={classes.questionTwo}>
                <Grid item xs={3}>
                  <RadioButtons
                    title={t('question:label:mwi:conditions')}
                    disabled={readOnlyMode}
                    value={formik.values.hasReadConditions}
                    onSelectedValueChanged={(value: number) => formik.setFieldValue('hasReadConditions', value)}
                    errorMessage={formik.errors.hasReadConditions}
                  />
                </Grid>
                <DialogButton
                  className={classes.dialogButton}
                  textId={'button:label:mwi:measure:conference:conditions'}
                  title={'button:label:mwi:conditions'}
                />
              </div>
            </div>
          </>
        )}
      </Grid>
    </div>
  );
};

export default Conference;
