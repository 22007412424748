import PDFZip from '../sdk/com/apiomat/frontend/missio/PDFZip';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '.';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import AbstractClientDataModel from '../sdk/com/apiomat/frontend/AbstractClientDataModel';
import { savePdfZip } from '../utils/pdfExport.utils';
import { onDownloadZip } from '../utils/file.utils';
import Offer from '../sdk/com/apiomat/frontend/missio/Offer';

/** STATE */
export interface PdfExportState {
  zippedFiles: PDFZip;
  tasksToExport: AbstractClientDataModel[];
  offersToExport: Offer[]
  exporting: 'idle' | 'pending' | 'succeeded' | 'failed' | 'loading-more';
}

const initialState: PdfExportState = {
  zippedFiles: null,
  tasksToExport: [],
  offersToExport: [],
  exporting: 'idle',
};

/** SLICE */
const pdfExportSlice = createSlice({
  name: 'pdfExport',
  initialState,
  reducers: {
    exportTasks: state => {
      state.exporting = 'pending';
    },
    exportTasksSuccess: (state, action: PayloadAction<PDFZip>) => {
      state.exporting = 'succeeded';
      state.zippedFiles = action.payload;
      state.tasksToExport = [];
    },
    exportTasksFailed: state => {
      state.exporting = 'failed';
    },
    exportOffers: state => {
      state.exporting = 'pending';
    },
    exportOffersSuccess: (state, action: PayloadAction<PDFZip>) => {
      state.exporting = 'succeeded';
      state.zippedFiles = action.payload;
      state.offersToExport = [];
    },
    exportOffersFailed: state => {
      state.exporting = 'failed';
    },
    addOrDeleteTaskToExport: (state, action: PayloadAction<AbstractClientDataModel>) => {
      const id = action.payload.ID;
      if(state.tasksToExport.find(element => element.ID === id)){
        state.tasksToExport= state.tasksToExport.filter(element => element.ID !== id);
      } else {
        state.tasksToExport.push(action.payload);
      }
    },
    addOrDeleteOfferToExport: (state, action: PayloadAction<Offer>) => {
      const id = action.payload.ID;
      if(state.offersToExport.find(element => element.ID === id)){
        state.offersToExport = state.offersToExport.filter(element => element.ID !== id);
      } else {
        state.offersToExport.push(action.payload);
      }
    },
    setOrDeleteAllTasks: (state, action: PayloadAction<AbstractClientDataModel[]>) => {
      state.tasksToExport = action.payload;
    },
    setOrDeleteAllOffers: (state, action: PayloadAction<Offer[]>) => {
      state.offersToExport = action.payload;
    }
  },
});

export const pdfExportActions = pdfExportSlice.actions;
export const pdfExportReducer = pdfExportSlice.reducer;

/** SAGAS */
function* onExportTasks() {
  const tasksToExport: AbstractClientDataModel[] = yield select((state: AppState) => state.pdfExport.tasksToExport);

  try {
    const zipedDocuments: PDFZip = yield call(() => savePdfZip(tasksToExport));
    yield put(pdfExportActions.exportTasksSuccess(zipedDocuments));
    if (zipedDocuments?.fileURL != null) yield call(() => onDownloadZip(zipedDocuments.fileURL));
  } catch (error) {
    yield put(pdfExportActions.exportTasksFailed());
  }
}

function* onExportOffers() {
  const offersToExport: Offer[] = yield select((state: AppState) => state.pdfExport.offersToExport);

  try {
    const zipedDocuments: PDFZip = yield call(() => savePdfZip(offersToExport));
    yield put(pdfExportActions.exportOffersSuccess(zipedDocuments));
    if (zipedDocuments?.fileURL != null) yield call(() => onDownloadZip(zipedDocuments.fileURL));
  } catch (error) {
    yield put(pdfExportActions.exportOffersFailed());
  }
}

export function* pdfExportSaga() {
  yield all([takeLatest(pdfExportActions.exportTasks, onExportTasks), takeLatest(pdfExportActions.exportOffers, onExportOffers)]);
}
