import React, { ReactNode } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Theme, makeStyles, Paper } from '@material-ui/core';
import EmptyMessage from './EmptyMessage';
import MUser from '../../sdk/com/apiomat/frontend/missio/MUser';
import State from '../../sdk/com/apiomat/frontend/missio/State';
import Contact from '../../sdk/com/apiomat/frontend/missio/Contact';
import Offer from '../../sdk/com/apiomat/frontend/missio/Offer';

const useStyles = makeStyles((theme: Theme) => ({
  head: {
    color: theme.palette.common.black,
  },
  headOneThird: {
    color: theme.palette.common.black,
    width: '33%',
  },
  tableBodyRoot: {
    borderBottom: 'none',
    maxWidth: 300,
  },
}));

export interface DataGridColumn<T> {
  header: ReactNode;
  valueExtractor: (dataRecord: T) => string | number | Date | boolean | undefined | MUser | State | Contact | Offer;
  component?: (dataRecord: T, changeHandler: (dataRecord: T) => void) => React.ReactNode;
}

export interface DataGridProps {
  datasource: any[];
  columns: DataGridColumn<any>[];
  emptyMessage: {
    title: string | React.ReactNode;
    component: string | React.ReactNode;
  };
  onChange?: (dataRecord: any) => void;
  headOneThird?: boolean;
}

const DataGrid = (props: DataGridProps) => {
  const { columns, datasource, emptyMessage, onChange, headOneThird } = props;
  const classes = useStyles();
  if (datasource === undefined || datasource.length === 0) {
    return <EmptyMessage title={emptyMessage.title}>{emptyMessage.component}</EmptyMessage>;
  }

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                classes={{
                  head: headOneThird ? classes.headOneThird : classes.head,
                }}
                key={index}
              >
                {column.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {datasource.map((dataRecord, dataRecordIndex) => (
            <TableRow hover key={`dataRecord_${dataRecordIndex}`}>
              {columns.map((column, columnIndex) => (
                <TableCell
                  classes={{
                    root: classes.tableBodyRoot,
                  }}
                  key={`column_${dataRecordIndex}_${columnIndex}`}
                >
                  {column.component === undefined
                    ? column.valueExtractor(dataRecord)
                    : column.component(dataRecord, x => {
                      if (onChange === undefined) {
                        return;
                      }
                      onChange(x);
                    })}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};
export default DataGrid;
