import Report from '../sdk/com/apiomat/frontend/missio/Report';
import { StateType } from '../enums/StateType';
import { ApplicationNewState } from './application-state';
import TargetGroup from '../sdk/com/apiomat/frontend/missio/TargetGroup';
import Monitoring from '../sdk/com/apiomat/frontend/missio/Monitoring';
import { postFile } from './file.utils';
import CostPlan from '../sdk/com/apiomat/frontend/missio/CostPlan';
import { costPlanCategories } from '../value-maps/CostPlanCategories';
import { CostDeviations } from '../store/report';

import MSettings from '../sdk/com/apiomat/frontend/missio/MSettings';

export const getSettings = async (): Promise<MSettings[]> => {
  return MSettings.getMSettingss();
};

export const saveReport = async (report: Report, newStatus: StateType): Promise<Report> => {
  const measure = report.measure;
  const costPlan = report.costPlan;
  const currencyConversionTable = report.costPlan.currencyConversionTable;

  const currentStatus = report.state?.name;
  if (currentStatus === StateType.rejected) {
    report.state.rejectionReason = '';
  }

  const attachmentsToUpload = report.attachments.filter(
    el => Boolean((el as any).dao.file) && Boolean((el as any).dao.isDeleted) === false
  );
  const attachmentsToDelete = report.attachments.filter(el => Boolean((el as any).dao.isDeleted) && Boolean(el.ID));

  /** Update measure */
  if (measure.ID) {
    await measure.save();
  } else {
    await measure.save();
    await report.postMeasure(measure);
  }

  await costPlan.save();

  /** Update currency conversion table */
  if (currencyConversionTable.ID) {
    await currencyConversionTable.save();
  } else {
    await currencyConversionTable.save();
    await costPlan.postCurrencyConversionTable(currencyConversionTable);
  }

  /** Upload new attachments */
  for (const attachment of attachmentsToUpload) {
    await postFile(attachment, report);
  }

  /** Delete attachments that are marked as deleted **/
  await Promise.all(attachmentsToDelete.map(el => el.delete()));

  /** Update state */
  report.state.name = newStatus;
  await report.save();
  return report;
};

export const initMissingAttributesInReport = (report: Report): Report => {
  if (!report.monitoring) {
    (report as any).hashmap.monitoring = new Monitoring();
    (report as any).dao.monitoring = report.monitoring;
  }

  if (!report.targetGroup) {
    (report as any).hashmap.targetGroup = new TargetGroup();
    (report as any).dao.targetGroup = report.targetGroup;
  }

  return report;
};

export const prepareReportForSaving = (report: Report) => {
  (report as any).dao.monitoring = report.monitoring.toJson();
  (report as any).dao.targetGroup = report.targetGroup.toJson();
  (report as any).dao.state = report.state.toJson();
  (report.costPlan as any).dao.costPlanItems = report.costPlan.costPlanItems.map(item => item.toJson());
  (report.costPlan as any).dao.currencyRates = report.costPlan.currencyRates.map(item => item.toJson());
  (report.costPlan as any).dao.financialPlan = report.costPlan.financialPlan.toJson();
  (report.costPlan as any).dao.financialPlan.financialPlanItems = report.costPlan.financialPlan.financialPlanItems.map(item =>
    item.toJson()
  );
  (report.costPlan
    .currencyConversionTable as any).dao.currencyConversions = report.costPlan.currencyConversionTable.currencyConversions.map(item =>
    item.toJson()
  );
  (report.costPlan
    .currencyConversionTable as any).dao.sourceCurrencyRates = report.costPlan.currencyConversionTable.sourceCurrencyRates.map(item =>
    item.toJson()
  );
  (report.costPlan
    .currencyConversionTable as any).dao.targetCurrencyRates = report.costPlan.currencyConversionTable.targetCurrencyRates.map(item =>
    item.toJson()
  );
};

export const calculateDeviations = (costPlan: CostPlan): CostDeviations => {
  return {
    projectActivities: getDeviationPerCostCategory(costPlan, 'cost-plan:cost-plan-category:project-activities') || 0,
    investments: getDeviationPerCostCategory(costPlan, 'cost-plan:cost-plan-category:investments') || 0,
    personnel: getDeviationPerCostCategory(costPlan, 'cost-plan:cost-plan-category:personnel') || 0,
    projectManagement: getDeviationPerCostCategory(costPlan, 'cost-plan:cost-plan-category:project-management') || 0,
  };
};

const getDeviationPerCostCategory = (costPlan: CostPlan, categoryValue: string) => {
  const categoryKey = costPlanCategories.find(category => category.value === categoryValue).key;
  const filteredCostPlanItems = costPlan.costPlanItems.filter(item => item.costPlanCategory === categoryKey);
  const totalSumEURPerCategory = filteredCostPlanItems.map(item => item.totalSumEUR).reduce((a, b) => a + b, 0);

  const calculatedDeviation = filteredCostPlanItems
    .map(item => (item.actualTotalSum === 0 ? 0 : (item.totalSumEUR / totalSumEURPerCategory) * (item.actualTotalSum / item.totalSum) || 0))
    .reduce((a, b) => a + b, 0);
  return calculatedDeviation === 0 ? 0 : calculatedDeviation - 1;
};

export const updateReportStatus = async (report: Report, newStatus: ApplicationNewState): Promise<Report> => {
  report.state.name = newStatus.status;
  report.state.rejectionReason = newStatus.message;
  await report.save();

  return report;
};
