import React, { memo, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OfferType } from '../../../enums/OfferType';
import MisMeasureFields from './MisMeasureFields';
import MwiMeasureFields from './MwiMeasureFields';
import { mwiMeasureDropDownItems } from '../../../value-maps/MwiMeasureDropDownItems';
import { misMeasureDropDownItems } from '../../../value-maps/MisMeasureDropDownItems';
import Report from '../../../sdk/com/apiomat/frontend/missio/Report';
import { alphabeticalSort } from '../../../utils/sort.util';
import SimpleDropdown from '../../Selection/SimpleDropDown';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
  dropDownContainer: {
    paddingTop: 30,
    paddingBottom: 30,
  },
}));

export interface MeasureFormProps {
  readOnlyMode: boolean;
  data?: any;
  measureName: string | undefined | null;
  measureType: string | undefined | null;
  onMeasureChange: (name: string) => void;
  report?: Report;
  isReport?: boolean;
  isAmendment?: boolean;
  isProposal?: boolean;
}

const MeasureForm = memo((props: MeasureFormProps) => {
  const { readOnlyMode, data, measureName, measureType, onMeasureChange, isReport, isAmendment, isProposal } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const measureDropDownItems = useMemo(
    () =>
      (measureType === OfferType.mis ? misMeasureDropDownItems : mwiMeasureDropDownItems)
        .map(el => ({
          key: el.key,
          value: t(el.value as string),
        }))
        .sort(alphabeticalSort),
    [ measureType, t ]
  );

  return (
    <>
      <div className={ classes.dropDownContainer }>
        <SimpleDropdown
          title={ measureName ? t(`info:title:measure:${ measureType }:${ measureName }`) : t('tab:title:measure') }
          helperText={ measureName ? t('tab:title:measure') : undefined }
          disabled={ readOnlyMode || isReport || isAmendment || isProposal }
          items={ measureDropDownItems }
          selectedItemKey={ measureName || '' }
          onSelectedItemChange={ onMeasureChange }
          errorMessage={ isEmpty(measureName) ? t('mandatory_field') : undefined }
        />
      </div>
      { measureType === OfferType.mis ? (
        <MisMeasureFields readOnlyMode={ readOnlyMode } data={ data } isReport={ isReport }
                          measureName={ measureName }/>
      ) : (
        <MwiMeasureFields readOnlyMode={ readOnlyMode } data={ data } isReport={ isReport }
                          measureName={ measureName }/>
      ) }
    </>
  );
});

export default MeasureForm;
