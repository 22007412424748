import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppState } from '../../store';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import FullScreenLoadingIndicator from '../../components/Loading/FullScreenLoadingIndicator';
import { reportActions } from '../../store/report';
import { StateType } from '../../enums/StateType';
import Report from '../../sdk/com/apiomat/frontend/missio/Report';
import { OfferFormProvider } from '../../components/Forms/context/OfferFormContext';
import NewReportTabs from './NewReportTabs';

export default () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string | undefined }>();

  useEffect(() => {
    if (id) {
      dispatch(reportActions.loadCurrentReport(id));
    }
  }, [ dispatch, id ]);

  const { currentReport, loadingReports, loadingCurrentReport, currentContacts, isSharedReport } = useSelector(
    (state: AppState) => state.report
  );

  const report = currentReport?.report;

  const reportState = report?.state?.name;
  const readOnly =
    reportState === StateType.completed ||
    reportState === StateType.approved ||
    reportState === StateType.easydor ||
    reportState === StateType.easydorError ||
    isSharedReport;

  if (loadingCurrentReport === 'pending') {
    return <FullScreenLoadingIndicator/>;
  }

  if (loadingReports === 'pending') {
    return <LoadingIndicator/>;
  }
  if (!report) {
    return null;
  }

  const onTitleChange = (title: string) => {
    report.offerName = title;
    dispatch(reportActions.updateCurrentReport(report));
  };

  const updateReport = (data: Report) => {
    dispatch(reportActions.updateCurrentReport(data));
  };

  return (
    <OfferFormProvider key={ Boolean(id) ? `report-${ id }` : 'new-report' }
                       data={ report }
                       onChange={ updateReport }>
      <NewReportTabs report={ report }
                     isSharedReport={ isSharedReport }
                     currentContacts={ currentContacts }
                     measureName={ report.measure?.name }
                     measureType={ report.measureType }
                     readOnly={ readOnly }
                     title={ report.offerName }
                     onTitleChange={ onTitleChange }>
      </NewReportTabs>
    </OfferFormProvider>
  );
};
