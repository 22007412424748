import PDFZip from '../sdk/com/apiomat/frontend/missio/PDFZip';
import AbstractClientDataModel from '../sdk/com/apiomat/frontend/AbstractClientDataModel';
import Offer from '../sdk/com/apiomat/frontend/missio/Offer';
import Survey from '../sdk/com/apiomat/frontend/missio/Survey';
import Report from '../sdk/com/apiomat/frontend/missio/Report';
import Audit from '../sdk/com/apiomat/frontend/missio/Audit';
import OfferEvaluation from '../sdk/com/apiomat/frontend/missio/OfferEvaluation';

export const savePdfZip = async (documentMap: AbstractClientDataModel[]): Promise<PDFZip> => {
  const documents = new PDFZip();

  const filesToZip = {};
  documentMap.forEach(item => {
    if (
      item instanceof Report ||
      item instanceof Offer ||
      item instanceof Survey ||
      item instanceof Audit ||
      item instanceof OfferEvaluation
    ) {
      filesToZip[item?.ID] = item?.modelName;
    }
  });
  documents.filesToZip = filesToZip;
  await documents.save();

  return documents;
};
