import Offer from '../sdk/com/apiomat/frontend/missio/Offer';
import { OfferType } from '../enums/OfferType';
import { Report } from '../sdk/com/apiomat/frontend/missio';

export const isOfferTypeMis = (offer: Offer | Report): boolean => {
  return offer.measureType === OfferType.mis;
};

export const isOfferTypeMwi = (offer: Offer | Report): boolean => {
  return offer.measureType === OfferType.mwi;
};
