import { AbstractClientDataModel } from '../sdk/com/apiomat/frontend';

export type LoadReferencedDataResult = {
  id: string;
  failures: string[];
};

export const tryLoad = async (call: Promise<unknown> | undefined, failureText: string): Promise<string | null> => {
  if (!call) {
    return null;
  }

  try {
    await call;
    return null;
  } catch (e) {
    console.warn(failureText, e);

    return failureText;
  }
};

export const executeInSequence = async <T>(...steps: (() => Promise<T>)[]) => {
  const results: T[] = [];

  for (const step of steps) {
    results.push(await step());
  }

  return results;
};
