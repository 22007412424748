import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import MUser from '../sdk/com/apiomat/frontend/missio/MUser';
import { useTranslation } from 'react-i18next';
import { userActions, UserContactIDChange } from '../store/users';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/index';
import LoadingIndicator from '../components/Loading/LoadingIndicator';
import { Roles } from '../enums/Roles';
import Button from '@mui/material/Button';
import { Clear, Done, Person, SupervisorAccount } from '@material-ui/icons';
import ContactInfo from './Contact/ContactInfo';

interface TableUser {
  firstName: string;
  lastName: string;
  email: string;
  churchRole: string;
  churchCountry: string;
  contactId: string;
  activityStatus: 'active' | 'inactive' | 'not-verified';
  administrator: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  userListItemContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  userListItemParameters: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  userListActiveParameterStatus: {
    textAlign: 'center',
    borderRadius: 4,
    backgroundColor: '#508E5E',
  },
  userListInactiveParameterStatus: {
    textAlign: 'center',
    borderRadius: 4,
    backgroundColor: 'red',
  },
  userListButtonContainer: {
    flex: 1,
    display: 'flex',
  },
  buttonSpacing: {
    paddingRight: 5,
    paddingLeft: 5,
  },
  deleteAdminButton: {
    color: theme.palette.error.main,
  },
  deactivateAdminButton: {
    minWidth: '8em',
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(userActions.loadUsers());
  }, [dispatch]);

  const { users, loading } = useSelector((state: AppState) => state.user);
  const { user } = useSelector((state: AppState) => state.auth);

  if (loading === 'pending') {
    return <LoadingIndicator />;
  }

  const handleDeleteClicked = async (user: MUser) => {
    dispatch(userActions.deleteUser(user));
  };

  const handleActivateClicked = async (user: MUser) => {
    dispatch(userActions.changeUserActivation({ user, isActive: 1 }));
  };

  const handleDeactivateClicked = async (user: MUser) => {
    dispatch(userActions.changeUserActivation({ user, isActive: 0 }));
  };

  const handleAddAdminRoleClicked = async (user: MUser) => {
    dispatch(userActions.addAdminRole(user));
  };

  const handleDeleteAdminRoleClicked = async (user: MUser) => {
    dispatch(userActions.deleteAdminRole(user));
  };

  const saveUserContact = (userContactProps: UserContactIDChange) => {
    dispatch(userActions.changeUserContactID(userContactProps));
  };

  const columns: Array<MUIDataTableColumnDef> = [
    {
      name: 'firstName',
      label: t('user-first-name'),
    },
    {
      name: 'lastName',
      label: t('user-last-name'),
    },
    {
      name: 'email',
      label: t('user-email'),
    },
    {
      name: 'churchRole',
      label: t('user-church-role'),
    },
    {
      name: 'churchCountry',
      label: t('user-church-country'),
    },
    {
      name: 'contactId',
      label: t('user-contact-id'),
      options: {
        customBodyRenderLite: dataIndex => <ContactInfo user={users[dataIndex]} saveNavContactId={saveUserContact} />,
      },
    },
    {
      name: 'activityStatus',
      label: t('user-activity-status'),
      options: {
        filter: false,
        customBodyRenderLite: dataIndex => {
          const isActive = users[dataIndex].hasVerifiedAccount && users[dataIndex].isActive;
          return (
            <Button
              fullWidth
              variant="outlined"
              startIcon={isActive ? <Done /> : <Clear />}
              color={isActive ? 'success' : 'error'}
              onClick={() => (isActive ? handleDeactivateClicked(users[dataIndex]) : handleActivateClicked(users[dataIndex]))}
              disabled={user.ID === users[dataIndex].ID || !users[dataIndex].hasVerifiedAccount}
            >
              {t(!user.hasVerifiedAccount ? 'user-not-verified-status' : user.isActive ? 'user-active-status' : 'user-inactive-status')}
            </Button>
          );
        },
      },
    },
    {
      name: 'administrator',
      label: t('user-administrator-actions'),
      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: dataIndex => {
          const isAdmin = Boolean((users[dataIndex].roles || []).includes(Roles.ADMIN));
          return (
            <Button
              variant="outlined"
              startIcon={isAdmin ? <SupervisorAccount /> : <Person />}
              onClick={() => (isAdmin ? handleDeleteAdminRoleClicked(users[dataIndex]) : handleAddAdminRoleClicked(users[dataIndex]))}
              disabled={user.ID === users[dataIndex].ID}
            >
              {t(isAdmin ? 'user-role-admin' : 'user-role-user')}
            </Button>
          );
        },
      },
    },
  ];

  const data: Array<TableUser> = users.map(user => {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      churchRole: user.churchRole,
      churchCountry: user.churchCountry,
      contactId: user.navContactId,
      activityStatus: !user.hasVerifiedAccount ? 'not-verified' : user.isActive ? 'active' : 'inactive',
      administrator: Boolean((user.roles || []).includes(Roles.ADMIN)),
    };
  });

  return (
    <div className={classes.container}>
      <MUIDataTable
        title={t('user-management')}
        data={data}
        columns={columns}
        options={{
          filterType: 'checkbox',
          print: false,
          download: true,
          downloadOptions: {
            filename: 'users',
            filterOptions: {
              useDisplayedColumnsOnly: true
            }
          },
          filter: false,
          sort: false,
          viewColumns: true,
          search: false,
          isRowSelectable: dataIndex => user.ID !== users[dataIndex].ID,
          onRowsDelete: rowsToDelete => {
            const usersToDelete = rowsToDelete.data.map(dataRow => users[dataRow.dataIndex]);
            usersToDelete.map(user => handleDeleteClicked(user));
            return;
          },
        }}
      />
    </div>
  );
};
