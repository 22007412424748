import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../../components/Loading/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StateType } from '../../enums/StateType';
import { AppState } from '../../store';
import { ListItemIcon, makeStyles } from '@material-ui/core';
import { Close, Done, Visibility } from '@material-ui/icons';
import { Flaky, QuestionMark, Warning } from '@mui/icons-material';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { Link } from 'react-router-dom';
import ActionMenu from '../../components/ActionMenu/ActionMenu';
import { ActualDeviationStatus } from '../../components/ActualDeviationStatus.tsx/ActualDeviationStatus';
import ReasonDialog from '../../components/Dialogs/ReasonDialog';
import EnquiryReasonDialog from '../../components/Dialogs/ReasonDialog';
import { Report, State } from '../../sdk/com/apiomat/frontend/missio';
import { reportActions } from '../../store/report';
import { ApplicationNewState } from '../../utils/application-state';
import { MenuItem, Tooltip } from '@mui/material';
import ReportIconStatus from '../../components/ReportIconStatus/ReportIconStatus';
import { CommentHistory } from '../../components/CommentHistory/CommentHistory';

interface TableReport {
  indicatorIcon: JSX.Element;
  application: string;
  creatorProjectNr: string;
  deadline: string;
  status: string;
  completed: StateType;
  targetCompleted: boolean;
  commentHistory: State;
  actions: Report;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [ activeReport, setActiveReport ] = useState(null);

  const [ isRejectionDialogOpen, setIsRejectionDialogOpen ] = useState(false);
  const [ rejectionMessage, setRejectionMessage ] = useState('');

  const [ isEnquiryDialogOpen, setIsEnquiryDialogOpen ] = useState(false);
  const [ enquiryMessage, setEnquiryMessage ] = useState('');

  const statuses = [
    StateType.completed,
    StateType.enquiryAnswered,
    StateType.easydorError
  ];

  const query = statuses
    .map(status => `state.name=='${ status }'`)
    .join(' or ');

  useEffect(() => {
    dispatch(reportActions.loadReports(query));
  }, [ dispatch, query ]);

  const { reports, loadingReports, loadingCurrentReport, incompleteReports } = useSelector((state: AppState) => state.report);
  const isIncomplete = (report: Report) =>
    report.state?.name !== StateType.unsavedChanges && report.state?.name !== StateType.cloud && !!incompleteReports[report.id];

  const columns: Array<MUIDataTableColumnDef> = [
    {
      name: 'indicatorIcon',
      label: ' ',
      options: {
        display: true,
        searchable: false,
      },
    },
    {
      name: 'application',
      label: t('reports:header:application')
    },
    {
      name: 'creatorProjectNr',
      label: `${ t('reports:header:creator') }/${ t('reports:header:project-number') }`
    },
    {
      name: 'deadline',
      label: t('reports:header:deadline')
    },
    {
      name: 'status',
      label: t('reports:header:status')
    },
    {
      name: 'completed',
      label: t('reports:header:completed'),
      options: {
        customBodyRender: (value) => {
          return (
            <ReportIconStatus state={ value }></ReportIconStatus>
          );
        }
      }
    },
    {
      name: 'targetCompleted',
      label: t('reports:header:target-completed'),
      options: {
        customBodyRender: (value) => {
          return (
            <ActualDeviationStatus hasDeviations={ value }/>
          );
        }
      }
    },
    {
      name: 'commentHistory',
      label: ' ',
      options: {
        customBodyRender: (value) => {
          return <CommentHistory state={ value } displayLastComment={ true }/>;
        }
      }
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = `action-menu-${ tableMeta.rowIndex }`;

          return (
            <div className={ classes.actionButton }>
              <ActionMenu menuId={ id } icon={ <Flaky/> } disabled={isIncomplete(value.ID)}>
                <MenuItem component={ Link } to={ `/tasks/report/new/${ value.ID }` }>
                  <ListItemIcon>
                    <Visibility/>
                  </ListItemIcon>
                  { t('reports:actions:view') }
                </MenuItem>
                { value?.state?.name !== StateType.easydorError && ([
                    <MenuItem onClick={ () => openEnquiryDialog(value) }>
                    <ListItemIcon>
                        <QuestionMark/>
                    </ListItemIcon>
                      { t('reports:actions:question') }
                  </MenuItem>,
                    <MenuItem onClick={ () => onAccept(value) }>
                    <ListItemIcon>
                        <Done/>
                    </ListItemIcon>
                      { t('reports:actions:approve') }
                  </MenuItem>,
                    <MenuItem onClick={ () => openRejectionDialog(value) }>
                    <ListItemIcon>
                        <Close/>
                    </ListItemIcon>
                      { t('reports:actions:reject') }
                    </MenuItem> ]
                ) }
              </ActionMenu>
            </div>
          );
        },
        filter: false,
        searchable: false,
        sort: false,
        viewColumns: false
      }
    }
  ];

  const data: Array<TableReport> = reports.map(report => {
    return {
      indicatorIcon: isIncomplete(report) ? (
        <Tooltip title={'Failed to load all required dependencies'}>
          <Warning color={'warning'} />
        </Tooltip>
      ) : (
        <></>
      ),
      application: report.offer?.name,
      creatorProjectNr: report.offer?.projectNumber ? report.offer?.projectNumber : report.offer?.creator,
      deadline: report?.dueDate?.toLocaleDateString('de-DE'),
      status: t(`reports:status:${ report.state?.name }`),
      completed: report?.state?.name as StateType,
      targetCompleted: report.hasCostPlanDeviations === 1,
      commentHistory: report.state,
      actions: report
    };
  });

  const openRejectionDialog = (report: Report) => {
    setActiveReport(report);
    setIsRejectionDialogOpen(true);
  };

  const openEnquiryDialog = (report: Report) => {
    setActiveReport(report);
    setIsEnquiryDialogOpen(true);
  };

  const cancelEnquiryDialog = () => {
    setIsEnquiryDialogOpen(false);
    setEnquiryMessage('');
    setActiveReport(null);
  };

  const cancelRejectionDialog = () => {
    setIsRejectionDialogOpen(false);
    setRejectionMessage('');
    setActiveReport(null);
  };

  const onAccept = (activeReport: Report) => {
    dispatch(reportActions.updateCurrentReport(activeReport));
    const newStatus: ApplicationNewState = { message: '', status: StateType.approved };
    dispatch(reportActions.updateReportStatus(newStatus));
  };

  const onReject = () => {
    dispatch(reportActions.updateCurrentReport(activeReport));
    const newStatus: ApplicationNewState = { message: rejectionMessage, status: StateType.rejected };
    dispatch(reportActions.updateReportStatus(newStatus));
    setIsRejectionDialogOpen(false);
  };

  const sendEnquiryMessage = () => {
    dispatch(reportActions.updateCurrentReport(activeReport));
    const newStatus: ApplicationNewState = { message: enquiryMessage, status: StateType.enquiry };
    dispatch(reportActions.updateReportStatus(newStatus));
  };

  return loadingReports === 'pending' || loadingCurrentReport === 'pending' ? (
    <LoadingIndicator/>
  ) : (
    <div className={ classes.container }>
      <MUIDataTable
        title={ t('all-reports') }
        data={ data }
        columns={ columns }
        options={ {
          selectableRows: 'none',
          print: false,
          download: false,
          filter: false,
          sort: false,
          viewColumns: false,
          search: false
        } }
      />
      <EnquiryReasonDialog
        isOpen={ isEnquiryDialogOpen }
        onCancelButtonClick={ cancelEnquiryDialog }
        onSaveButtonClick={ sendEnquiryMessage }
        content={ t('enquiry-dialog:content') }
        onChange={ event => {
          setEnquiryMessage(event.target.value);
        } }
        value={ enquiryMessage }
      />
      <ReasonDialog
        isOpen={ isRejectionDialogOpen }
        onCancelButtonClick={ cancelRejectionDialog }
        onSaveButtonClick={ onReject }
        content={ t('reject-dialog:content') }
        onChange={ event => {
          setRejectionMessage(event.target.value);
        } }
        value={ rejectionMessage }
      />
    </div>
  );
};
